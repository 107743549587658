import React from "react";
import "./Dictionary.css";

function Definations({ definitions, dicNotFound }) {
  const regex = /(<([^>]+)>)/gi;
  return (
    <div>
      <div className="mt-2">
        <p>Definitions</p>
        <div className="mt-1">
          <ul className="list-unstyled dictionary">
            {definitions.length === 0
              ? dicNotFound
                ? "Sorry, no results found"
                : null
              : definitions.map((definitions, index) => (
                  <li key={index}>
                    {definitions.partOfSpeech === "noun" ? (
                      <>
                        noun
                        <span className="synonym ml-3">
                          {definitions &&
                            definitions.text &&
                            definitions.text.replace(regex, "")}
                        </span>
                      </>
                    ) : null}
                  </li>
                ))}
          </ul>
          {/* {dicNotFound ? "Sorry, no results found" : null} */}
        </div>
      </div>
    </div>
  );
}

export default Definations;
