import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UseRecorder = () => {
  // const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder()
          .then(setRecorder)
          .catch((err) => {
            toast.error("media device is not found", {
              position: "top-right",
              autoClose: 7000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            setInterval(function () {
              return (window.location.href = "/prompt");
            }, 1000);

            // document.getElementById("hideToggleAudio").style.display = "none";
            // document.getElementById("showToggleAudio").style.display =
            //   "inline-block";
          });
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      if (recorder === null) {
      } else {
        recorder.start();
      }
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = () => {};

    recorder.addEventListener("dataavailable", handleData);

    return () => recorder.removeEventListener("dataavailable", handleData);
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
    const stopAudioReco = document.getElementById("stopAudioRecording");

    if (stopAudioReco === null) {
    } else {
      stopAudioReco.click();
    }
  };
  return [isRecording, startRecording, stopRecording];
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}
export default UseRecorder;
