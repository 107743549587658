import React from "react";
import { CirclePicker } from "react-color";
import { colorArray } from "../../utils/colorCodes";
import "./ColorPicker.css";
function ColorPicker({ colorAction, setColorAction }) {
  const [color, setColor] = React.useState("");

  return (
    <>
      <span>
        {" "}
        <img
          src="assets/images/font-color.svg"
          alt=""
          srcSet=""
          width="27px"
          onClick={() => setColorAction((colorAction) => !colorAction)}
        />
      </span>
      {colorAction ? (
        <div className="color_picker">
          <CirclePicker
            color={color}
            onChange={(e) => {
              setColor(e.hex);
              setColorAction(false);
              document.execCommand("foreColor", false, e.hex);
            }}
            colors={colorArray && colorArray}
          />
        </div>
      ) : null}
    </>
  );
}

export default ColorPicker;
