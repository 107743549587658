import React, { useState } from "react";

import { useDispatch } from "react-redux";

import "../App.css";
import { GoogleLogin } from "react-google-login";
import Facebook from "react-facebook-login";
import { signup } from "../services/actions";

import logoIcon from "../images/logo.svg";
import AppleAuth from "../AppleAuth/AppleAuth";

function NativeAppGoogleAuth() {

  return (
    <div className="main_native_heading">
      <div className="google_native">
        <div className="row">
          <div className="profile-img-native">
            <img
              src={logoIcon}
              alt="profile_img"
              height="120px"
              width="120px;"
            />
          </div>

          <div className="col-12">
            <AppleAuth native={true} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NativeAppGoogleAuth;
