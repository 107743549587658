import { showStoryBoardsConstants } from "../actions/constants";
const initialState = {
  showSingleScriptStoryboard: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case showStoryBoardsConstants.SHOW_STORYBOARD_REQUEST:
      state = {
        ...state,
        authenticating: true,
      };
      break;
    case showStoryBoardsConstants.SHOW_STORYBOARD_SUCCESS:
      state = {
        ...state,
        showSingleScriptStoryboard: action.payload.storyboards,
      };
      break;

    default:
  }

  return state;
};
