import React from "react";
import firebase from "firebase/compat/app";
import { OAuthProvider } from "firebase/auth";
import { getAuth, signInWithPopup } from "firebase/auth";
import AppleIcon from "@mui/icons-material/Apple";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "./AppleAuth.css";
import { login, signup } from "../services/actions";
import { useDispatch } from "react-redux";
const AppleAuth = ({ native }) => {
  let nativeWindow = native || false;

  const dispatch = useDispatch();

  var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  firebase.initializeApp(firebaseConfig);

  const signInWithApple = async () => {
    const provider = new OAuthProvider("apple.com");

    provider.addScope("email");
    provider.addScope("name");
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // The signed-in user info.
        console.log(
          result &&
            result.user &&
            result.user.reloadUserInfo &&
            result.user.reloadUserInfo.providerUserInfo &&
            result.user.reloadUserInfo.providerUserInfo[0],
          "result.user"
        );

        let data =
          result &&
          result.user &&
          result.user.reloadUserInfo &&
          result.user.reloadUserInfo.providerUserInfo &&
          result.user.reloadUserInfo.providerUserInfo[0];

        console.log(data, "data====");

        if (data) {
          const user = {
            first_name: data.firstName || "",
            last_name: "",
            email: data.email,
            password: process.env.REACT_APP_PASSWORDS,
            unique_id: data.rawId,
            type: "Apple",
          };

          // console.log(user, "user===");

          dispatch(signup(user));

          if (nativeWindow === true) {
            if (!data.hasOwnProperty("error")) {
              const link = document.createElement("a");
              let url =
                `scriptivelyapp://response=` +
                encodeURIComponent(JSON.stringify(user));
              link.href = url;
              document.body.appendChild(link);
              link.click();
            }
          } else {
            const user_login = {
              email: user.email,
              password: user.password,
              unique_id: user.unique_id,
              type: user.type,
            };
            dispatch(login(user_login));
          }
        }
      })
      .catch((error) => {
        // Handle Errors here.
        console.log(error, "firebase error");

        alert(error.message);

        // ...
      });
  };

  return (
    <button className="apple-sign-in" onClick={() => signInWithApple()}>
      <AppleIcon className="mb-1" /> Sign in with Apple
    </button>
  );
};

export default AppleAuth;
