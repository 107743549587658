import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Error.css";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

function Error() {
  const [action, setAction] = useState(true);

  useEffect(() => {
    window //console.clear();
      .setTimeout(() => {
        setAction(false);
      }, 1000);
  }, []);

  return (
    <>
      {action ? (
        <Box sx={{ width: "100%", paddingTop: "20px", textAlign: "center" }}>
          <CircularProgress disableShrink />
          <p>Please wait...</p>
        </Box>
      ) : (
        <div className="error">
          <div className="cell">
            <div className="eror-img">
              <img src="assets/images/error.png" alt="error" width="100%" />
              <button className="btn btn-warning">
                <Link to="/" className="error-back-home">
                  Back to home
                </Link>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Error;
