import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  showAllFolderScripts,
  showAllScripts,
  showAllStoryBoards,
  userlogout,
} from "../services/actions";
import { updateScriptConstants } from "../services/actions/constants";
import { add_story_board } from "../utils/ApiList";
import { addScriptUnderFolderController } from "../utils/DashboardController";
import { toastOptions } from "../utils/ToastAlert";

function AddNewScript({
  setIsActive,
  addScript,
  attributedText,
  // description,
  setLoader,
  allScript,
  setScriptImage,

  setScriptView,
  setShowScriptId,
  // setScr_id,
  setEditAction,
  setAllScript,
  setShowScriptAtrrText,
  setShowScriptTitle,
  setTitle,
  setDescription,
  setSelectActive,

  setStoryBoard2,

  setScriptTitle,
  setScriptPara,
  setNewScriptId,

  setShowScriptUuid,
  setScrAlignment,
  underFolderAction,
  setEmailAndPrintAction,
  setScrEditTextSize,
  setScrColor,
  // allScript,
  setToggleSelect,
  setStoryBoardHeading,
}) {
  const user_id = localStorage.getItem("userId");
  const dispatch = useDispatch();

  const addNewScript = async () => {
    setStoryBoard2([]);

    const description = document.getElementById(
      "exampleFormControlTextarea1"
    ).value;
    try {
      setIsActive(false);
      setLoader(true);
      if (addScript.scrTitle === "") {
        toast.warn("All fields are mandatory", {
          hideProgressBar: true,
        });
        setLoader(false);
      } else {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/add_script`, {
            user_id: addScript.user_id,
            scrTitle: addScript.scrTitle,
            scrText: description,
            scrAttrText: description,
            scrEditTextSize: addScript.scrEditTextSize,
            scrPromptTextSize: addScript.scrPromptTextSize,
            scrPromptSpeed: addScript.scrPromptSpeed,
            textMargin: addScript.textMargin,
            marker: false,
            markerX: addScript.markerX,
            uuid: addScript.uuid,
            scrManualScrolling: false,
            scrCameraRecordMe: false,
            scrShowMeFullScreen: false,
            scrShowMeThumbnail: false,
            scrVoiceRecordMe: false,
            scrColor: "#000",
            scrAlignment: "left",
            scrMirror: "",
            scrInvert: "",
            scrFontFamily: "arial",
          })
          .then(async function (response) {
            dispatch(showAllScripts());

            const { message, data, success, code } = response.data;

            localStorage.setItem("uuid", data.uuid);
            localStorage.setItem("scriptId", data.id);
            localStorage.setItem("title", data.scrTitle);
            localStorage.setItem("attrTxt", data.scrAttrText);
            localStorage.setItem("scriptText", data.scrText);

            setScrEditTextSize(data.scrEditTextSize);
            let scriptAttributedText = data.scrAttrText;
            document.getElementById("main_script").innerHTML =
              scriptAttributedText ? scriptAttributedText : "Type here...";
            if (message === "Script Successfully Added") {
              setToggleSelect(true);
              setScrColor("#000");
              if (underFolderAction !== null) {
                let scrInnerScriptData = {
                  script_id: data.id,
                  folder_id: underFolderAction,
                };
                await addScriptUnderFolderController(scrInnerScriptData);
                dispatch(showAllFolderScripts(underFolderAction));
              }
              setEmailAndPrintAction(true);
              toast.success("Script has been successfully added", toastOptions);
              let scriptLocalData = {
                user_id: data.user_id,
                script_id: data.id,
                scrTitle: data.scrTitle,
                scrText: data.scrText,
                scrAttrText: data.scrAttrText,
                uuid: data.uuid,
                scrAlignment: data.scrAlignment,
                scrInvert: data.scrInvert,
                scrMirror: data.scrMirror,
                textMargin: data.textMargin,
                scrEditTextSize: data.scrEditTextSize,
                scrPromptTextSize: data.scrPromptTextSize,
                scrShowMeThumbnail: data.scrShowMeThumbnail,
                scrShowMeFullScreen: data.scrShowMeFullScreen,
                scrCameraRecordMe: data.scrCameraRecordMe,
                scrVoiceRecordMe: data.scrVoiceRecordMe,
                scrManualScrolling: data.scrManualScrolling,
                scrColor: "#000000",
              };
              localStorage.setItem(
                "scriptLocalData",
                JSON.stringify(scriptLocalData)
              );

              setScriptImage("assets/images/add.svg");
              setStoryBoardHeading(true);
              setShowScriptUuid(data.uuid);

              const allsc = [...allScript, data];

              setEditAction(true);
              setScriptTitle(data.scrTitle);
              setScriptView(data);
              setShowScriptTitle(data.scrTitle);
              setScriptPara(data.scrText);
              setShowScriptAtrrText(data.scrText);
              setAllScript(allsc);
              // allScript = data;
              setNewScriptId(data.id);
              setShowScriptId(data.id);
              const attributedScriptText = data.scrText;
              let attrSampleText = attributedScriptText
                .split("<p ></p>")
                .join("");
              localStorage.setItem("attrTxt", attrSampleText);
              attributedText = attrSampleText;
              localStorage.setItem("t", data.scrTitle);
              document.getElementById("exampleFormControlTextarea1").value = "";
              document.getElementById("title").value = "";
              setTitle("");
              setScrAlignment(data.scrAlignment);
              dispatch({
                type: updateScriptConstants.SELECTED_SCRIPT,
                payload: { script: data },
              });
              try {
                axios
                  .post(`${add_story_board}`, {
                    user_id: user_id,
                    script_id: data.id,
                    storyboardName: "Introduction",
                    storyboardText: "",
                    storyboardAttrText: "",
                    draggableId: "",
                  })
                  .then(async function (response) {
                    setIsActive(false);
                    const resData = response.data.data;
                    const script_id = resData && resData.script_id;

                    await dispatch(showAllStoryBoards(script_id));

                    // const data = response.data.data;

                    // setStoryBoard2((prevArray) => [...prevArray, data]);
                  });
              } catch (error) {
                console.log(error);
              }
              try {
                axios
                  .post(`${add_story_board}`, {
                    user_id: user_id,
                    script_id: data.id,
                    storyboardName: "Objective",
                    storyboardText: "",
                    storyboardAttrText: "",
                    draggableId: "",
                  })
                  .then(async function (response) {
                    const resData = response.data.data;

                    const script_id = resData && resData.script_id;

                    await dispatch(showAllStoryBoards(script_id));
                    const boardOrder = boardOrderFun(resData);
                    setIsActive(false);
                  });
              } catch (error) {
                console.log(error);
              }
            }
          })
          .catch(function (error) {
            let { response } = error;

            const { message, code } = response.data;

            if (code == 401) {
              toast.warn(message);
              setTimeout(() => {
                dispatch(userlogout());
                window.location.href = "/";
              }, 2000);
            } else {
              toast.error(message);
            }
          });

        setSelectActive(true);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
    // }
  };

  const boardOrderFun = (data) => {
    console.log(data, "new board");
    return data;
    // let x = [];
    // let script_id;
    // data &&
    //   data.forEach((i) => {
    //     x.push(i.id);
    //     script_id = i.script_id;
    //   });

    // const res = await axios.post(set_story_board_orders, {
    //   user_id: localStorage.getItem("userId"),
    //   script_id: script_id,
    //   order: x.toString(),
    // });
  };

  return (
    <div>
      <div
        className="modal fade "
        id="exampleModalCenter1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered "
          role="document"
          style={{ maxWidth: "700px" }}
        >
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5
                className="modal-title"
                id="exampleModalLongTitle"
                // onClick={modalScript}
              >
                Add Script
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="tab-content">
                    <div id="addScript" className="container tab-pane active">
                      <br />

                      {/* Add Script Modal */}

                      <form>
                        <div className="row">
                          <div className="col-lg-12 p-0">
                            <div className="form-group">
                              <label htmlFor="title">Title</label>
                              <input
                                type="text"
                                className="form-control modal-input"
                                placeholder="Enter tilte"
                                name="title"
                                id="title"
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 p-0">
                            <div className="form-group">
                              <label htmlFor="exampleFormControlTextarea1">
                                Description
                              </label>
                              <textarea
                                className="form-control text-area-modal"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                placeholder="Enter description"
                                onChange={(e) => setDescription(e.target.value)}
                                // onKeyPress={(e) =>
                                //   e.key === "Enter" ? addNewScript(e) : null
                                // }
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div
                      id="addStory-Board"
                      className="container tab-pane fade"
                    >
                      <br />
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0">
                <button
                  type="submit"
                  className="btn btn-primary sub-btn"
                  onClick={() => addNewScript()}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewScript;
