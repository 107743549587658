import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpeechRecognition from "react-speech-recognition";
import { toast } from "react-toastify";

import {
  scrollSpeedAction,
  scrollSpeedUpDownKeyAction,
  subscriptionStatus,
} from "../../services/actions";
import {
  scrollingFooterController,
  stopScrollController,
} from "../../utils/ScrollController";

import DemoAudio from "../AudioRecord/DemoAudio";

import "./Range.css";
import StopVideoRecordIcon from "./stop.png";
import queryString from "query-string";
import {
  addFreeOrdersInDb,
  get_control_values,
  show_scripts,
} from "../../utils/ApiList";
import axios from "axios";
import { settingAction } from "../../services/actions/setting.actions";
import { IOSSlider } from "../../utils/slider";
import { stopManualPageScrollController } from "../../utils/ManuallyPageScrollController/manuallyPageScrollController";

var Url = require("url-parse");
let testRange = 0;

function Seek(props) {
  const {
    isRecording,
    stopAudioRecordingFun,
    stopTimer,

    stopScrollData,
    videoStop,
    setVideoStop,
    stopVideoRecording,

    setAudioRecordChecked,
    startVoiceRecognition,
    setStartVoiceRecognition,
    voiceRecoError,
    nativeTitleAction,
    title,
    setStartRecordIcon,
    setFooterPenalAction,
    settings,
    handleSpaceKeyEvent,
    setTimers,
  } = props;

  const { scrollValue } = useSelector((state) => state.scrollValue);

  const globalState = useSelector((state) => state);

  var url = new Url(window.location.href);

  const substringData = url && url.hash.substring(5);
  const query = queryString.parse(substringData && substringData);
  let user_id = query && query.response;
  let nativeWindow = query && query.nativeApp && query.nativeApp.slice(0, -1);
  const dispatch = useDispatch();

  React.useEffect(async () => {
    dispatch(subscriptionStatus(user_id && user_id.slice(9)));
    if (nativeWindow && nativeWindow === "true") {
      const res = await axios.post(addFreeOrdersInDb, {
        user_id: user_id && user_id.slice(9),
      });
    }
  }, [dispatch]);

  let subStatus =
    globalState.subscriptionStatus.subscriptionStatus &&
    globalState.subscriptionStatus.subscriptionStatus.payment_status_code;

  let ForeverFreeAction =
    subStatus && subStatus == "2"
      ? true
      : subStatus && subStatus === "0"
      ? true
      : false;

  testRange = scrollValue;

  const [freeTrailAction, setFreeTrailAction] = useState(false);

  const showTabs = () => {
    setStartRecordIcon(false);

    if (isRecording === true) {
      stopAudioRecordingFun();
      stopTimer();

      scrollingFooterController();
      stopScrollController(stopScrollData);
      setAudioRecordChecked(true);
      document.getElementById("stopAudioRecording").click();
    } else if (videoStop === true) {
      stopVideoRecording();
      stopTimer();
      scrollingFooterController();
      stopScrollController(stopScrollData);
      setVideoStop(true);
      document.getElementById("stopAudioRecording").click();
    } else {
      stopTimer();
      scrollingFooterController();
      stopScrollController(stopScrollData);
      document.getElementById("stopAudioRecording").click();
    }
  };

  const h = () => {
    if (props.time.h === 0) {
      return "";
    } else {
      return (
        <span>{props.time.h >= 10 ? props.time.h : "0" + props.time.h}</span>
      );
    }
  };

  //voice recognition stop function
  const stopVoiceRecogination = () => {
    if (voiceRecoError) {
      stopTimer();
      scrollingFooterController();
      // setStartVoiceRecognition(false);
      document.getElementById("stop__recording").style.marginBottom = "0px";
      var attrText = localStorage.getItem("attrTxt");
      localStorage.setItem("attrTxt", attrText);

      document.getElementById("voiceRecoMedia").style.display = "none";
    } else {
      // stopSpeechToText();
      SpeechRecognition.stopListening();

      stopTimer();
      scrollingFooterController();
      // setStartVoiceRecognition(false);
      stopAudioRecordingFun();
      stopVideoRecording();
      document.getElementById("stop__recording").style.marginBottom = "0px";
      let attrText = localStorage.getItem("attrTxt");
      localStorage.setItem("attrTxt", attrText);
    }
  };

  const handlePremium = () => {
    setFooterPenalAction(false);

    if (ForeverFreeAction && freeTrailAction) {
      // toast.warn(`Free account is limited to 90 seconds`);
      toast.warn(
        `You reached the limit of your ${settings.audVidRecognitionValue} ${
          settings.audVidRecognitionUnit == "sec" ? `Seconds` : `Minutes`
        } free plan! Please click on your Account to upgrade to Unlimited.`
      );
      startVoiceRecognition ? stopVoiceRecogination() : showTabs();
    } else {
      startVoiceRecognition ? stopVoiceRecogination() : showTabs();
    }
  };

  React.useEffect(() => {
    const { m, s } = props.time;

    let isRealSecond = m * 60 + s;

    let isControlSecs = null;
    if (settings) {
      isControlSecs =
        settings && settings.audVidRecognitionUnit == "sec"
          ? parseInt(settings.audVidRecognitionValue)
          : parseInt(settings.audVidRecognitionValue) * 60;
    }
    // if (s == 3) {
    if (isRealSecond == isControlSecs) {
      setFreeTrailAction(ForeverFreeAction);
      ForeverFreeAction && handlePremium();
    }
  }, [props.time]);

  React.useEffect(() => {
    setFreeTrailAction(ForeverFreeAction);
  }, [ForeverFreeAction]);

  const handleStop = () => {
    setFreeTrailAction(false);
    showTabs();
  };

  return (
    <div>
      <div style={{ backgroundColor: "#000" }}>
        <DemoAudio nativeTitleAction={nativeTitleAction} title={title} />
      </div>

      {startVoiceRecognition ? (
        <div className="stop-scroll-div" id="pause__button">
          <div id="btnStop">
            <img
              id="stop__recording"
              src={StopVideoRecordIcon}
              alt="start video recording"
              onClick={() => handleStop()}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="stop-scroll-div" id="pause__button">
            <div id="btnStop">
              <img
                id="stop__recording"
                // onClick={() => stopAudioRecordingFun()}
                // disabled={!isRecording}
                src={StopVideoRecordIcon}
                alt="start video recording"
                onClick={() => handleStop()}
              />
            </div>
          </div>
          <div className="row d-flex">
            <div className="row">
              <div className="col-lg-12 range-div">
                <span id="count__down">
                  {h()} {props.time.m < 10 ? `0${props.time.m}` : props.time.m}:
                  {props.time.s < 10 ? `0${props.time.s}` : props.time.s}
                </span>
                <span id="seek__range">
                  <IOSSlider
                    className="range-footer seek-slider"
                    aria-label="ios slider"
                    id="scriptZomm"
                    valueLabelDisplay="on"
                    min={1}
                    value={scrollValue}
                    max={3}
                    onChange={(e) =>
                      dispatch(scrollSpeedAction(e.target.value))
                    }
                    step={0.01}
                  />
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Seek;
export { testRange };
