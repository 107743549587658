import React from "react";
import { toast } from "react-toastify";
import { openEmailController } from "../../utils/EmaiController";

function EmailMenu({ emailAndPrintAction }) {
  return (
    <>
      {" "}
      <div
        onClick={() =>
          emailAndPrintAction
            ? openEmailController()
            : toast.warning("Please select the script", {
                autoClose: 4000,
                hideProgressBar: true,
              })
        }
      >
        <img src="assets/images/share.svg" alt="" width="22px" />
      </div>
    </>
  );
}

export default EmailMenu;
