import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import axios from "axios";
import LoadingOverlay from "react-loading-overlay";

import "./StripePayment.css";
import {
  addFreeOrdersInDb,
  create_payment_intent_without_userId,
  pay_now,
} from "../../utils/ApiList.js";
import { ToastContainer, toast } from "react-toastify";
import logo from "../SubscriptionComponent/images/logo.png";

import { refreshPageController } from "../../utils/RefreshPage.js";

import RedirectAppStripeCheckoutForm from "./RedirectAppStripeCheckoutForm.js";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DefaultStripeForm from "./DefaultStripeForm";
import { useHistory } from "react-router-dom";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

export default function MainRedirectStripePage() {
  let stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
  const [clientSecret, setClientSecret] = useState("");
  const [loader, setLoader] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isAccount, setIsAccount] = useState(false);
  const [isVerify, setIsVerify] = useState(false);

  let user_id;
  let price;
  let currency;
  let plan_id;
  let native_application = false;
  let redirect_status;
  let product_id;
  user_id = new URLSearchParams(window.location.search).get("user_id");
  price = new URLSearchParams(window.location.search).get("price");
  currency = new URLSearchParams(window.location.search).get("currency");
  plan_id = new URLSearchParams(window.location.search).get("price_id");
  native_application = new URLSearchParams(window.location.search).get(
    "native_application"
  );
  redirect_status = new URLSearchParams(window.location.search).get(
    "redirect_status"
  );
  product_id = new URLSearchParams(window.location.search).get("product_id");

  const getStripePromise = async () => {
    user_id = new URLSearchParams(window.location.search).get("user_id");
    price = new URLSearchParams(window.location.search).get("price");
    currency = new URLSearchParams(window.location.search).get("currency");
    plan_id = new URLSearchParams(window.location.search).get("price_id"); // plan_id is price id

    native_application = new URLSearchParams(window.location.search).get(
      "native_application"
    );
    redirect_status = new URLSearchParams(window.location.search).get(
      "redirect_status"
    );
    stripePromise = await loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
  };
  let sub = null;
  useEffect(async () => {
    setLoader(true);

    getStripePromise();
    // Create PaymentIntent as soon as the page loads
    const getPaymentIntent = async () => {
      let subscriptionData = localStorage.getItem("subscriptionData");

      let res;

      if (native_application === "true") {
        sub = {
          user_id: user_id && user_id,
          price: price && parseFloat(price),
          currency: currency && currency,
          plan_id: plan_id && plan_id,
          product_id: product_id && product_id,
          native_application: true,
        };

        localStorage.setItem("subscriptionData", JSON.stringify(sub && sub));

        res = await axios.post(pay_now, {
          user_id: user_id && user_id,
          amount: price && parseFloat(price * 100),
          currency: currency && currency,
          plan_id: plan_id && plan_id,
        });
      } else {
        sub = JSON.parse(subscriptionData);

        if (sub && sub.native_application === true) {
        } else {
          // alert("test");
          sub.user_id = localStorage.getItem("userId");
          sub.plan_id = sub.price_id;
        }

        if (sub) {
          res = await axios.post(pay_now, {
            user_id:
              sub.native_application === true
                ? sub.user_id
                : localStorage.getItem("userId"),
            amount: parseFloat(sub.price * 100),
            currency: sub.currency,
            plan_id:
              sub.native_application === true ? sub.plan_id : sub.price_id, // plan_id is a plan price id
          });
        }
      }

      if (res) {
        if (res.data.code === 200) {
          const paymentIntent =
            res.data &&
            res.data.data &&
            res.data.data.paymentIntent &&
            res.data.data.paymentIntent;

          setClientSecret(paymentIntent);

          setSubscriptionData(sub);
        } else {
          toast.error(res.data.message);
          refreshPageController();
        }
      }

      setLoader(false);

      // .then((data) => );
    };
    getPaymentIntent();
  }, []);

  let type = new URLSearchParams(window.location.search).get("type");

  useEffect(() => {
    console.log(type, "type=====");
    if (type && type == 1) {
      setIsAccount(false);
      return;
    }
    if (!type) {
      setIsAccount(true);
      return;
    }
    setIsAccount(false);
  }, []);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    let userDetails = JSON.parse(localStorage.getItem("verifiedUserDetails"));

    setFirstName(userDetails.firstName);
    setLastName(userDetails.lastName);
    setEmail(userDetails.email);
    setPassword(userDetails.password);
  }, []);

  const backToHome = (type) => {
    localStorage.removeItem("verifiedUserDetails");
    localStorage.removeItem("userId");
    if (type) {
      window.location.href = `/app/subscription/checkout`;
    } else {
      window.location.href = `/app/subscription/checkout?type=1`;
    }
  };

  return (
    <LoadingOverlay active={false} spinner>
      <div className="checkout-page new-redirect-checkout-page">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="logo-top mt-2">
              <img src={logo} className="img-fluid" />
              <h2 className="pt-2 pb-3">Get Scriptively Pro</h2>
            </div>
            <div className={`toggle-text `}>
              <span
                onClick={() => {
                  backToHome("new");
                }}
                className={`${!isAccount && "tab-color"}`}
              >
                New Account
              </span>
              <span>|</span>
              <span
                onClick={() => {
                  backToHome();
                }}
                className={isAccount && "tab-color"}
              >
                Existing Account
              </span>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <p className="text-signup text-left checkout-titles">
              {isAccount ? "Upgrade Existing Account" : "Create Your Account"}
            </p>
            <p></p>
          </div>
        </div>

        {!isAccount ? (
          <>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control custom-input"
                    name="firstName"
                    placeholder="First Name"
                    value={firstName}
                    disabled
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    disabled
                    type="lastName"
                    className="form-control custom-input"
                    name="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control custom-input"
                    name="email"
                    disabled
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control custom-input"
                    name="password"
                    placeholder="Password"
                    value={password}
                    disabled
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <button
                    // onClick={(e) => createNewAccount(e)}
                    className={`btn btn-primary btn-lg btn-block custom-btn border-0 btn-login create-account-checkout 
                      verify-account
                    `}
                  >
                    Account Created
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div
              className="col-lg-9 col-md-8 col-sm-8 col-6"
              style={{ padding: "0 15px" }}
            >
              <div className="form-group">
                <input
                  type="text"
                  className="form-control custom-input"
                  name="email"
                  disabled
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div
              className="col-lg-3  col-md-4 col-sm-4 col-6"
              style={{ padding: "0 10px 0px 0px" }}
            >
              <div className="form-group">
                <button
                  className={`btn btn-primary btn-lg btn-block custom-btn border-0 btn-login verify-account
                  `}
                >
                  Account Verified
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="pb-3">
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <RedirectAppStripeCheckoutForm
                subscriptionData={subscriptionData}
              />
            </Elements>
          )}
        </div>

        <div className="row pb-5 mt-2">
          <div className="col-12 text-center">
            <div>
              <span>
                <VerifiedUserIcon className="shield_icon" />
              </span>
              <span>
                Secure Payments by{" "}
                <span className="text-shield-stripe">Stripe</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer className="alert-msg" autoClose={3000} />
    </LoadingOverlay>
  );
}
