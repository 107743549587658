import axiosInstance from "../../helpers/axios";

import { userConstants } from "./constants";
import { toast } from "react-toastify";
import { addFreeOrdersInDb } from "../../utils/ApiList";
import { login } from "./auth.actions";
// import { normalText } from "../../utils/ToastAlert";
// var userId;
export const signup = (user, planType) => {
  return async (dispatch) => {
    dispatch({
      type: userConstants.USER_REGISTER_REQUEST,
    });

    const res = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/user_signup`,
      {
        ...user,
      }
    );

    if (res.status === 200) {
      const { message, data } = res.data;

      if (data) {
        const OrderDetail = await axiosInstance.post(addFreeOrdersInDb, {
          user_id: data && data.id,
        });
      }

      if (user.type === "Apple") {
        dispatch(login(user));

        dispatch({
          type: userConstants.USER_REGISTER_SUCCESS,
          payload: { message },
        });
        return;
      } else if (user.type === "Google") {
        dispatch(login(user));

        dispatch({
          type: userConstants.USER_REGISTER_SUCCESS,
          payload: { message },
        });
      } else if (user.type === "Facebook") {
        dispatch(login(user));
        dispatch({
          type: userConstants.USER_REGISTER_SUCCESS,
          payload: { message },
        });
      } else if (message === "User Already Exist") {
        toast.warn("User Already Exist, Please login", {
          hideProgressBar: true,
        });

        dispatch({
          type: userConstants.USER_REGISTER_FAIL,
          payload: { error: "User Already Exist" },
        });
      } else {
        const screenWidth = window.screen.width / 3;

        // signUpDefaultScript();
        toast.warn(message, {
          hideProgressBar: true,
        });
        dispatch(login(user, planType));

        dispatch({
          type: userConstants.USER_REGISTER_SUCCESS,
          payload: { message },
        });
      }
    } else {
      toast.error("something went wrong");
    }
  };
};

const addFreeTrailOrder = async (data) => {
  const res = await axiosInstance(addFreeOrdersInDb, {
    user_id: data.id,
  });

  console.log(res, "Order Details");
};
