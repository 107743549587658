import React from "react";
import logoIcon from "../../images/logo.svg";
import googleIcon from "../../images/google-play.png";
import appleIcon from "../../images/app-store.png";
import { isMobile, isIOS, isAndroid } from "react-device-detect";

const AppTemplate = () => {
  const handleClearLocalStorage = () => {
    console.clear();
    localStorage.clear();
  };
  return (
    <div className="main_native_heading">
      <div className="">
        <div className="row">
          <div className="col-12 text-center mt-5">
            <div className="profile-img-native mt-5 ml-0">
              <img
                src={logoIcon}
                alt="profile_img"
                height="120px"
                width="120px;"
              />
            </div>

            {isMobile && isAndroid ? (
              <a href={process.env.REACT_APP_ANDROID}>
                <img
                  onClick={() => handleClearLocalStorage()}
                  src={googleIcon}
                  alt="Google Play"
                  width="140"
                  height="50"
                />
              </a>
            ) : (
              <a href={process.env.REACT_APP_IOS}>
                <img
                  onClick={() => handleClearLocalStorage()}
                  src={appleIcon}
                  alt="Google Play"
                  width="140"
                  height="50"
                />
              </a>
            )}

            <h2
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#000",
                marginTop: "20px",
              }}
            >
              Free Download{" "}
            </h2>
            <p style={{ fontSize: "14px", fontWeight: "400", color: "#000" }}>
              No credit card or in-app purchase required.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppTemplate;
