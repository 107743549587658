import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function AlertModal({ deleteScr }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    deleteScr();
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen} className="dropdown-item">
        Delete
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="board_cancel_btn">
            Cancel
          </button>
          <button onClick={() => handleDelete()} className="board_cancel_btn">
            OK
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
