import React from "react";
import { toast } from "react-toastify";

function PrintEmailMenuComponent({ handlePrint, emailAndPrintAction }) {
  return (
    <div>
      {emailAndPrintAction ? (
        <div onClick={handlePrint}>
          <img src="assets/images/print.svg" alt="" srcSet="" width="26px" />
        </div>
      ) : (
        <div
          onClick={() =>
            toast.warn("Please select the script?", {
              hideProgressBar: true,
            })
          }
        >
          <img src="assets/images/print.svg" alt="" srcSet="" width="26px" />
        </div>
      )}
    </div>
  );
}

export default PrintEmailMenuComponent;
