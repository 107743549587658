import React, { useState, useEffect } from "react";

import axios from "axios";
import styled from "styled-components";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import SidebarAlert from "./SidebarAlert";
import { toastOptions } from "../../utils/ToastAlert";
import AddNewScriptButton from "../AddNewScript/AddNewScriptButton";

import "./Sidebar.css";
// import { showAllFolderController } from "../../services/controller/DashboardController";
import { toast } from "react-toastify";
import LogoutModal from "../LogoutModal/LogoutModal";
import { useDispatch, useSelector } from "react-redux";
import { showAllStoryBoards, subscriptionStatus } from "../../services/actions";
import { add_folders } from "../../utils/ApiList";
import { updateScriptConstants } from "../../services/actions/constants";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({}))(MuiAccordionDetails);

function SideBar({
  show_folders,
  showAllScript,
  setEditAction,
  mfolder,
  showScriptFolder,
  scriptSelection,
  setToggleContextMenu,
  setLoader,
  setShowFolders,
  onFocus,
  folder_name,
  folderMsgError,
  setFolderName,
  setFolderMsgError,
  setFolderError,
  folderError,
  Alert,
  setSuccessFolderMsg,
  setShowScriptAtrrText,
  setShowScriptTitle,
  setStb,
  setScriptImage,
  setScriptTitle,
  setScriptPara,
  setShowScriptId,
  setScrTab,
  recentScrList,
  showAllFolderController,
  setCurrentScriptId,
  setEmailAndPrintAction,
  setBoardAction,
  setStoryBoardHeading,
  setScrAlignment,
  setIsActive,
  folderScript,
  setFolderScript,
  currentFolderId,
}) {
  const globalState = useSelector((state) => state);
  const dispatch = useDispatch();
  const paymentSubscription = globalState.subscriptionStatus.subscriptionStatus;
  const { settingData, userMode } = useSelector((state) => state.setting);
  let payStatusCode =
    paymentSubscription &&
    paymentSubscription.payment_status_code &&
    paymentSubscription.payment_status_code;

  const screenWidth = window.screen.width / 5;
  const [subLoader, setSubLoader] = useState(false);

  const user_id = localStorage.getItem("userId");
  const [contextId, setContextId] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [addFolderAction, setAddFolderAction] = useState(false);

  const [updateFolderName, setUpdateFolderName] = useState("");
  const [folderId, setFolderId] = useState(null);
  const [folderErr, setFolderErr] = useState("");

  // toggle sidebars
  const [expandedFolder, setExpandedFolder] = React.useState("panel2");

  const handleChangeFolder = (panel) => (event, newExpanded) => {
    setExpandedFolder(newExpanded ? panel : false);
  };
  const handleClickOpen = () => {
    setOpen(true);
    setFolderId(contextId.id);

    setUpdateFolderName(contextId.name);
  };

  const handleClickCreateFolder = () => {
    if (userMode && show_folders.length >= parseInt(settingData.folder_count)) {
      toast.warn(
        "You reached the limit of your " +
          settingData.folder_count +
          " Folders free plan! Please click on your Account to upgrade to Unlimited."
      );
      window.open(
        "/subscription",
        "_blank",
        `location=yes,height=600,width=800,scrollbars=yes,status=yes, top=100, left=250,  margin=0 auto,  frame=false,nodeIntegration=no`
      );
      return false;
    }
    setAddFolderAction(true);
  };
  const handleCreateFolderClose = () => {
    setFolderMsgError("");
    setFolderError(false);

    setAddFolderAction(false);
  };

  //create folder
  const createFolder = async (e) => {
    setSubLoader(true);
    try {
      e.preventDefault();
      var Boolean = false;

      const fname = folder_name.trim();
      if (fname === "") {
        toast.warn("Please enter the folder name.", {
          hideProgressBar: true,
        });
      } else {
        for (let folder of show_folders) {
          if (folder.name === fname) {
            Boolean = true;
          } else {
          }
        }
        if (Boolean === true) {
          folderMsgError("Folder is already exits...");
          setFolderName("");
          showAllFolderController(setShowFolders);
        } else {
          await axios
            .post(add_folders, {
              user_id: user_id,
              folder_name: fname,
            })
            .then(async function (response) {
              // window.location.reload();
              await showAllFolderController(setShowFolders);
              setFolderMsgError("");
              handleCreateFolderClose();
              toast.success("Folder has been successfully added", toastOptions);
            })
            .catch(function (error) {
              setLoader(false);

              let { response } = error;

              const { message, code } = response.data;

              if (code == 401) {
                toast.warn(message);
                setTimeout(() => {
                  window.location.href = "/";
                  localStorage.clear();
                  //console.clear();
                }, 1000);
              } else {
                toast.error(message);
              }
            });
        }
      }
      try {
        // showAllFolderController(setShowFolders);

        setSubLoader(false);

        setSuccessFolderMsg(true);
        setSuccessFolderMsg(true);
        setFolderName("");
      } catch (error) {}
    } catch (error) {
      setFolderMsgError("Folder is already exits...");
      setFolderError(true);
      setSubLoader(false);

      // setFolderName("");
    }
  };

  const handleClose = () => {
    setOpen(false);
    onFocus();
    setFolderName("");
  };

  const updateFolder = async (e) => {
    e.preventDefault();

    if (!updateFolderName) {
      toast.warning("Please enter the folder name", toastOptions);
      return false;
    }
    setSubLoader(true);
    try {
      var Boolean = false;

      for (let folder of show_folders) {
        if (folder.name === updateFolderName) {
          Boolean = true;
        } else {
        }
      }
      if (Boolean === true) {
        setFolderErr("Folder is already exits...");
        setUpdateFolderName("");
        setSubLoader(false);
      } else {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/edit_folder`, {
            user_id,
            folder_id: folderId,
            folder_name: updateFolderName,
          })
          .then((res) => {
            const msg = res.data.message;
            if (msg === "Folder Successfully Updated") {
              showAllFolderController(setShowFolders);
              setSubLoader(false);

              if (currentFolderId == folderId) {
                setFolderScript(updateFolderName);
              }

              handleClose();
              toast.success(
                "Folder has been successfully updated",
                toastOptions
              );
            } else {
              setSubLoader(false);
            }
          })
          .catch((error) => {
            setSubLoader(false);

            let { response } = error;

            const { message, code } = response.data;

            if (code == 401) {
              toast.warn(message);
              setTimeout(() => {
                window.location.href = "/";
                localStorage.clear();
                //console.clear();
              }, 1000);
            } else {
              toast.error(message);
            }
          });
      }
    } catch (error) {
      setSubLoader(false);
    }
  };

  const deleteSingleFolder = async () => {
    setLoader(true);
    await axios.post(`${process.env.REACT_APP_API_URL}/delete_folder`, {
      user_id,
      folder_id: contextId.id,
    });

    if (contextId.name === folderScript && folderScript.toString()) {
      showAllScript();
    }

    const folder = show_folders.filter((folder) => folder.id !== contextId.id);
    setShowFolders(folder);
    setLoader(false);
    toast.success("Folder has been successfully deleted", toastOptions);
  };

  function WhichButton(event, scripts) {
    setContextId(scripts);
  }

  //modal
  const recentScriptData = async (data) => {
    setLoader(true);
    setIsActive(true);
    setStb([]);
    setEditAction(true);
    document.getElementById("main_script").innerHTML = data.scrAttrText
      ? data.scrAttrText
      : "Type here...";
    setShowScriptAtrrText(data.scrAttrText);
    setShowScriptTitle(data.scrTitle);
    setEmailAndPrintAction(true);
    setScrAlignment(data.scrAlignment);

    setScrTab(data);
    setScriptImage("assets/images/add.svg");
    setStoryBoardHeading(true);
    setScriptTitle(data.scrTitle);
    setScriptPara(data.scrText);
    setShowScriptId(data.id);
    setCurrentScriptId(data.id);
    dispatch({
      type: updateScriptConstants.SELECTED_SCRIPT,
      payload: { script: data },
    });
    let script_id = data.id;
    await dispatch(showAllStoryBoards(script_id));
    setLoader(false);
    setTimeout(() => {}, 500);
  };

  const planStatus =
    payStatusCode && payStatusCode === "1"
      ? "Premium"
      : payStatusCode === "2"
      ? "Expired"
      : "Free Limited";

  useEffect(() => {
    dispatch(subscriptionStatus());
  }, []);

  useEffect(() => {
    if (payStatusCode === "2") {
      toast.warning(
        "Subscription Expired. Please make a payment.",
        toastOptions
      );
    }
  }, [payStatusCode]);

  const handleOpenSubscription = () => {
    window.open(
      "/subscription",
      "_blank",
      `location=yes,height=600,width=800,scrollbars=yes,status=yes, top=100, left=250,  margin=0 auto,  frame=false,nodeIntegration=no`
    );
  };

  return (
    <>
      <div
        onClick={() => setToggleContextMenu(false)}
        className="makeStyles-root-2"
      >
        {/* <nav id="sidebar"> */}
        <div className="sidebar-header">
          <img src="assets/images/main-logo.svg" alt="" />
        </div>

        <div className="add-new-script mb-3">
          <AddNewScriptButton setBoardAction={setBoardAction} />
        </div>

        <div className="recent-body mt-0">
          <div className="recent-script-title">
            {/* <img className="mr-3" src="assets/images/script.png" alt="" />{" "} */}
            <span style={{ fontSize: "1em" }}>Recent Scripts</span>
          </div>

          <ul className="recent-content-body">
            {recentScrList.length === 0
              ? null
              : recentScrList.map((data, index) => (
                  <li
                    key={index}
                    onClick={() => recentScriptData(data)}
                    className=""
                    style={{ marginTop: "12px" }}
                  >
                    <div
                      onClick={() => recentScriptData(data)}
                      className="m-2"
                      id="recent_text"
                    >
                      <img
                        className="mr-2"
                        src="assets/images/script.svg"
                        alt=""
                        width="20px"
                      />{" "}
                      {data.scrTitle}
                    </div>
                  </li>
                ))}
          </ul>
          {/* )} */}
        </div>

        {/* folder data  */}
        <div className="sidebar_add_header add_folder">
          <img
            src="assets/images/add.svg"
            alt=""
            onClick={handleClickCreateFolder}
            width="20px"
          />
          <span style={{ fontSize: "1em" }}>Folders</span>
        </div>
        <Accordion
          square
          expanded={expandedFolder === "panel2"}
          onChange={handleChangeFolder("panel2")}
          className="recent__script mt-0"
        >
          <div className="add-folder-main">
            {/* <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              className="recent-script-header p-0"
            >
              <Typography
                component={"span"}
                variant={"body2"}
                className="recent-script-header p-0"
              >
                <div className="sidebar_add_header add_folder ">
                  <img
                    src="assets/images/add.svg"
                    alt=""
                    onClick={handleClickCreateFolder}
                    width="20px"
                  />
                  <span style={{ fontSize: "1em" }}>Folders</span>
                </div>
              </Typography>
            </AccordionSummary> */}
            <div
              className="show-all-scripts"
              onClick={() => {
                showAllScript();
                dispatch(showAllStoryBoards());
              }}
            >
              <div className="all-scr get-all-scripts active">
                <span onClick={() => showAllScript()}>All Scripts</span>{" "}
              </div>
            </div>{" "}
          </div>
          <div className="folder-container">
            <AccordionDetails className="recent-body folder-body">
              <ul className=" custom-li-sidebar  list-unstyled list-group main-folder-div ">
                <div className="folder-ul-list">
                  {show_folders.length === 0
                    ? null
                    : show_folders.map((folder, index) => (
                        <FolderContainer
                          key={index}
                          id="singleItem"
                          onMouseDown={(e) => WhichButton(e, folder)}
                        >
                          <ContextMenuTrigger id="contextmenu2">
                            <div
                              className="col-lg-8 folder-list"
                              data-folders={folder.id}
                              onClick={() => {
                                showScriptFolder(folder.id);
                                dispatch(showAllStoryBoards());
                              }}
                            >
                              <li
                                style={{
                                  cursor: "pointer",
                                  marginTop: "12px",
                                }}
                              >
                                <div
                                  data-folders={folder.id}
                                  onClick={() => {
                                    showScriptFolder(folder.id);
                                    dispatch(showAllStoryBoards());
                                  }}
                                  id="folder_text"
                                >
                                  {/* <img
                                    className="mr-3 folder-icon"
                                    src="assets/images/folder-icon.svg"
                                    data-folders={folder.id}
                                    onClick={() => {showScriptFolder(folder.id)}}
                                    alt="folder"
                                  /> */}
                                  <span
                                    onClick={() => {
                                      showScriptFolder(folder.id);
                                      dispatch(showAllStoryBoards());
                                    }}
                                  >
                                    {folder.name}
                                  </span>
                                </div>
                              </li>
                            </div>
                            <div className="col-lg-4 folder-selection">
                              {scriptSelection && (
                                <input
                                  type="checkbox"
                                  name="folderInput"
                                  className="folder select-folders"
                                  value={folder.id}
                                  onClick={() => mfolder(folder)}
                                  style={{
                                    height: "20px",

                                    width: "50px",
                                    marginTop: "-20px",
                                    visibility: "visible",
                                  }}
                                  data-folder-id={folder.id}
                                />
                              )}
                            </div>
                          </ContextMenuTrigger>
                        </FolderContainer>
                      ))}
                </div>
              </ul>
            </AccordionDetails>
          </div>
        </Accordion>

        <ContextMenu id="contextmenu2">
          <MenuItem>
            <SidebarAlert deleteSingleFolder={deleteSingleFolder} />
          </MenuItem>
          <hr />
          <MenuItem onClick={() => handleClickOpen()}>
            <span className="deleteScript ml-3 p-1">Rename</span>
          </MenuItem>
        </ContextMenu>

        {/* <div className="w-100"> */}
        <Dialog
          open={addFolderAction}
          onClose={handleCreateFolderClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <LoadingOverlay active={subLoader} spinner>
            <DialogTitle id="alert-dialog-title" className="main_modal">
              {"Create Folder"}

              <div className="float-right">
                <i
                  className="fa fa-times close__icon"
                  onClick={handleCreateFolderClose}
                ></i>
              </div>
            </DialogTitle>
            <DialogContent>
              {/* <DialogContentText id="alert-dialog-description"> */}

              <DialogContentText>
                {folderError ? (
                  <Alert severity="warning">{folderMsgError}</Alert>
                ) : null}
              </DialogContentText>
              <form>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Folder name</label>
                      <input
                        type="text"
                        className="form-control modal-input"
                        placeholder="Enter folder name"
                        value={folder_name}
                        // onClick={onFocus}
                        onChange={(e) => setFolderName(e.target.value)}
                        onKeyPress={(e) =>
                          e.key === "Enter" ? createFolder(e) : null
                        }
                      />
                    </div>
                  </div>
                </div>
              </form>
              {/* </DialogContentText> */}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={createFolder}
                className="btn btn-primary sub-btn text-white"
              >
                Submit
              </Button>
            </DialogActions>
          </LoadingOverlay>
        </Dialog>

        {/* update folder modal */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <LoadingOverlay active={subLoader} spinner>
            <DialogTitle id="alert-dialog-title" className="main_modal">
              {"Update folder"}
              <div className="float-right">
                <i
                  className="fa fa-times close__icon"
                  onClick={handleClose}
                ></i>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText className=" text-danger">
                {folderErr}
              </DialogContentText>
              <form>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Folder name</label>
                      <input
                        type="text"
                        className="form-control modal-input"
                        placeholder="Enter folder name"
                        value={updateFolderName}
                        onChange={(e) => setUpdateFolderName(e.target.value)}
                        onClick={() => setFolderErr("")}
                        onKeyPress={(e) =>
                          e.key === "Enter" ? updateFolder(e) : null
                        }
                      />
                    </div>
                  </div>
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => updateFolder(e)}
                className="btn btn-primary sub-btn text-white"
              >
                Update
              </Button>
            </DialogActions>
          </LoadingOverlay>
        </Dialog>
        {planStatus && planStatus !== "Premium" && (
          <div className="profile-sidebar d-flex orange-line">
            <div className="logout-btn">
              <div
                className="inner-upgrade-content"
                onClick={() => handleOpenSubscription()}
              >
                <p className="free">
                  {" "}
                  <span className="pr-1">
                    <img
                      src="assets/images/carbon_upgrade.svg"
                      alt=""
                      srcset=""
                    />
                  </span>
                  {planStatus &&
                    planStatus !== "Premium" &&
                    "Upgrade to unlimited"}
                </p>
                <img
                  src="assets/images/rectangle.png"
                  className="pro-img"
                  alt="logout"
                  width="150px"
                  height={"30px"}
                />
              </div>
            </div>
          </div>
        )}

        {/* footer */}
        <div className="profile-sidebar d-flex">
          <div className="pro-img pl-2 mr-3 " style={{ cursor: "pointer" }}>
            <a
              onClick={() =>
                window.open(
                  "/profile",
                  "_blank",
                  `location=yes,height=700,width=900,scrollbars=yes,status=yes,  frame=false,nodeIntegration=no`
                )
              }
            >
              <img
                src="assets/images/O-NOPHOTO.svg"
                className="pro-img"
                alt="logout"
                width="24px"
              />
            </a>
          </div>
          <div className="profile-text text-right">
            <a
              // href="javascript:void(0);"
              onClick={() =>
                window.open(
                  "/profile",
                  "_blank",
                  `location=yes,height=700,width=900,scrollbars=yes,status=yes, top=10, left=${screenWidth},  frame=false,nodeIntegration=no`
                )
              }
            >
              Account
            </a>
          </div>
          <div className="logout-btn">
            <div>
              <p className="free"> {planStatus && planStatus}</p>
              <img
                src="assets/images/Flag.svg"
                className="pro-img"
                alt="logout"
                width="120px"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
const FolderContainer = styled.div`
  position: relative !important;
`;
