import { updateScriptConstants } from "../actions/constants";
const initialState = {
  userId: null,
  authenticate: false,
  authenticating: false,
  update: null,
};

export default (state = initialState, action) => {
  // console.log(action);
  // console.log()
  switch (action.type) {
    case updateScriptConstants.USER_UPDATE_REQUEST:
      state = {
        ...state,
        authenticating: true,
      };
      break;
    case updateScriptConstants.USER_UPDATE_SUCCESS:
      state = {
        ...state,
        update: action.payload.update,
        userId: action.payload.userId,
        authenticate: true,
        authenticating: false,
      };
      break;

    case updateScriptConstants.USER_UPDATE_FAIL:
      state = {
        ...initialState,
      };
      break;

    default:
  }

  return state;
};
