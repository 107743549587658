import { scrollingSpeedConstants, updateScriptConstants } from "./constants";
import store from "./../store/store";

const scrollSpeedAction = (value) => async (dispatch) => {
  dispatch({
    type: scrollingSpeedConstants.INCREASE_DECREASE_SPEED,
    payload: value,
  });
};

const scrollSpeedUpDownKeyAction = (type) => async (dispatch) => {
  let { scrollValue } = store.getState().scrollValue;
  dispatch({
    type: scrollingSpeedConstants.INCREASE_DECREASE_SPEED,
    payload:
      type === "Up"
        ? scrollValue !== 3
          ? scrollValue + 0.01
          : scrollValue
        : scrollValue !== 1
        ? scrollValue - 0.01
        : scrollValue,
  });
};

export { scrollSpeedAction, scrollSpeedUpDownKeyAction };
