import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DeleteAccount from "../components/LogoutModal/DeleteAccount";
import LogoutModal from "../components/LogoutModal/LogoutModal";
import SubscriptionModal from "../components/SubscriptionComponent/SubscriptionModal/SubscriptionModal";
import { subscriptionModal, subscriptionStatus } from "../services/actions";
import "./dashboard.css";
import Error from "./Error";
import packageJson from "../../package.json";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoadingOverlay from "react-loading-overlay";
import { ToastContainer } from "react-toastify";
import ReactPixel from "react-facebook-pixel";

function Profile() {
  const dispatch = useDispatch();

  const { loader } = useSelector((state) => state.requestLoader);

  useEffect(() => {
    //  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID, options);

    ReactPixel.pageView(); // For tracking page view
  }, []);

  const screenWidth = window.screen.width / 3;

  const [subscriptionAction, setSubscriptionAction] = useState(false);

  const user = localStorage.getItem("user");
  const globalState = useSelector((state) => state);

  let profile_data = JSON.parse(user);

  const openEmail = async () => {
    const subject = `Scriptively 1.0(version) Support`;
    const body = ``;
    let url = `mailto:support@scriptively.com?subject=${subject}&body=${body}`;
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    // console.log(a);

    a.click();
  };
  React.useEffect(() => {
    dispatch(subscriptionStatus());
  }, []);

  const paymentSubscription = globalState.subscriptionStatus.subscriptionStatus;

  let year = new Date().getFullYear();

  return (
    <>
      <LoadingOverlay active={loader} spinner>
        <div className="wrapper">
          {profile_data === null || profile_data === "" ? (
            <Error />
          ) : (
            <>
              <div id="content">
                <div className="">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <div className="heading-title mt-3">
                        <h5>
                          <b className="pl-2">Profile</b>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="profile_null_bg"></div>

                  <div className="row">
                    <div className="col-lg-12 profile-section">
                      {profile_data === null || profile_data === "" ? null : (
                        <div className="pro-content">
                          <h5 className="pro-heading">
                            <b style={{ textTransform: "capitalize" }}>
                              {profile_data === null || profile_data === ""
                                ? null
                                : profile_data.first_name}
                              <span>
                                {" "}
                                {profile_data.last_name === "" ||
                                profile_data.last_name === null
                                  ? null
                                  : profile_data.last_name}
                              </span>
                            </b>
                          </h5>
                          <p className="email">
                            {" "}
                            {profile_data.email === "" ||
                            profile_data.email === null
                              ? null
                              : profile_data.email}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 profile-bg-header  ">
                      <div className="pro-gray-shape d-flex">
                        <div style={{ width: "60%" }}>
                          <h5 className="pro-heading">My Account</h5>
                        </div>
                        <div
                          style={{ width: " 40%", textAlign: "right" }}
                          className="mr-2"
                        >
                          <p className="mb-0" style={{ fontSize: "12px" }}>
                            {paymentSubscription &&
                              paymentSubscription.payment_status &&
                              paymentSubscription.payment_status}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Link to="/subscription" className="w-100">
                      <div className="col-lg-12 profile-bg-header">
                        <div className="d-none">
                          <p id="sub-amount-total" className="d-none"></p>
                          <SubscriptionModal
                            subscriptionAction={subscriptionAction}
                          />
                        </div>
                        {/* <a href="/subscription"> */}
                        <div className="pro-gray-shape d-flex">
                          <div style={{ width: "60%" }}>
                            <h5 className="pro-heading">In App Purchases</h5>
                          </div>
                          <div
                            style={{ width: "40%", textAlign: "right" }}
                            className="mr-2"
                          >
                            <div>
                              <img
                                src="assets/images/arrow.svg"
                                width="8px"
                                alt="right-icon"
                              />
                            </div>
                          </div>
                        </div>
                        {/* </a> */}
                      </div>
                    </Link>
                    <div className="profile_null_bg"></div>
                    <br />
                    <div className="col-lg-12 profile-bg-header">
                      <a
                        href="https://scriptively.com/tutorials"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="pro-gray-shape d-flex">
                          <div style={{ width: "60%" }}>
                            <h5 className="pro-heading">How to Videos</h5>
                          </div>
                          <div
                            style={{ width: "40%", textAlign: "right" }}
                            className="mr-2"
                          >
                            <div>
                              <img
                                src="assets/images/arrow.svg"
                                width="8px"
                                alt="right-icon"
                              />
                            </div>
                          </div>
                        </div>
                      </a>{" "}
                    </div>
                    <div
                      className="col-lg-12 profile-bg-header"
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="pro-gray-shape d-flex"
                        onClick={() => openEmail()}
                      >
                        <div style={{ width: "60%" }}>
                          <h5
                            className="pro-heading"
                            onClick={() => openEmail()}
                          >
                            Contact Support
                          </h5>
                        </div>
                        <div
                          style={{ width: "40%", textAlign: "right" }}
                          className="mr-2"
                        >
                          <div>
                            <img
                              src="assets/images/arrow.svg"
                              width="8px"
                              alt="right-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-12 profile-bg-header"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="pro-gray-shape d-flex">
                        <div style={{ width: "60%" }}>
                          <h5 className="pro-heading">Write a Review</h5>
                        </div>
                        <div
                          style={{ width: "40%", textAlign: "right" }}
                          className="mr-2"
                        >
                          <div>
                            <img
                              src="assets/images/arrow.svg"
                              width="8px"
                              alt="right-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-lg-12 profile-bg-header"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="pro-gray-shape d-flex">
                        <div style={{ width: "60%" }}>
                          <LogoutModal />
                        </div>
                        <div
                          style={{ width: "40%", textAlign: "right" }}
                          className="mr-2"
                        >
                          <div>
                            <img
                              src="assets/images/arrow.svg"
                              width="8px"
                              alt="right-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-lg-12 mt-3 ml-2"
                      style={{ cursor: "pointer" }}
                    >
                      <div style={{ width: "60%" }}>
                        <p className="app_version">
                          Version {packageJson.version}
                        </p>
                        <p className="copyright">
                          Copyright &copy; {year}, Scriptively, LLC
                        </p>
                      </div>
                      <DeleteAccount />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <ToastContainer className="alert-msg" autoClose={3000} />
      </LoadingOverlay>
    </>
  );
}

export default Profile;
