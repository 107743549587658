import axios from "axios";
import { addNewScriptController } from "../components/GoogleDrive/services/GoogleDriveController";
import { toast } from "react-toastify";
import { toastOptions } from "./ToastAlert";
import { addNewScriptDropBoxController } from "./DropboxController";
export const uploadDropBoxFileController = async (
  upload,
  googleDriveSendData,
  showAllScript,
  setToggleSelect,
  setLoader,

  fileName,

  setEmailAndPrintAction
) => {
  // setLoaderTest(true);
  setLoader(true);
  const file = upload; //select file
  // console.log(file);

  const fileData = file === null ? null : file[0];
  var bodyFormData = new FormData();
  bodyFormData.append("file", fileData);

  const res = await axios({
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/read_file`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
  //handle success

  if (res.data !== "non zip file") {
    const msg = res.data.message;
    if (
      msg ===
        "<p>The uploaded file exceeds the maximum allowed size in your PHP configuration file.</p>" ||
      msg ===
        "<p>The filetype you are attempting to upload is not allowed.</p>" ||
      msg === "Unable to read this file due to Unwanted Content"
    ) {
      toast.warn(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      // setLoaderTest(false);
      setLoader(false);
    } else {
      let scrText = res.data.data.text && res.data.data.text;
      let scrAttrText =
        res.data.data.html && res.data.data.html.split("font-size").join("");
      let attrText = scrAttrText && scrAttrText.split("h1").join("p");
      let saveData;
      if (attrText === null || attrText === "") {
        saveData = {
          scrTitle: fileName,
          scrText: scrText,
          attrText: scrText,
        };
      } else {
        saveData = {
          scrTitle: fileName,
          scrText: scrText,
          attrText,
        };
      }

      let scriptId = await addNewScriptDropBoxController(
        saveData,
        googleDriveSendData,
        showAllScript,
        setToggleSelect,
        setLoader,
        setEmailAndPrintAction
      );
      // setLoaderTest(false);
      setLoader(false);
      setEmailAndPrintAction(true);
      console.log("setLoaderTest", scriptId);
      return scriptId;
    }
  } else {
    toast.error("Something went is not found", toastOptions);
    // setLoaderTest(false);
    setLoader(false);
  }
};
