import React, { Component } from "react";
import { saveAs } from "file-saver";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastAlert";

export default class DemoAudio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recordState: null,
    };
  }

  start = () => {
    this.setState({
      recordState: RecordState.START,
    });
  };

  stop = () => {
    this.setState({
      recordState: RecordState.STOP,
    });
  };

  //audioData contains blob and blobUrl
  onStop = (audioData) => {
    const blob = audioData.blob;

    let date = new Date();
    let sd = date.toISOString().substring(0, 10);
    let hh = date.getHours();
    let mm = date.getMinutes();
    let s = date.getSeconds();

    let fullDateTime = sd + " " + hh + "_" + mm + "_" + s;

    const { nativeTitleAction } = this.props;

    if (blob) {
      if (blob.size > 100) {
        if (nativeTitleAction) {
          if (blob) {
            const title = this.props.title;
            saveAs(blob, `Audio - ${title}-${fullDateTime}.wav`);
            toast.success("Audio recording saved successfully!", toastOptions);
          }
        } else {
          if (blob) {
            const title = localStorage.getItem("title");
            saveAs(blob, `Audio - ${title}-${fullDateTime}.wav`);
            toast.success("Audio recording saved successfully!", toastOptions);
          }
        }
      }
    }
  };

  render() {
    const { recordState } = this.state;

    return (
      <div>
        <AudioReactRecorder state={recordState} onStop={this.onStop} />

        <button id="startAudioRecording" onClick={this.start}>
          Start
        </button>
        <button id="stopAudioRecording" onClick={this.stop}>
          Stop
        </button>
      </div>
    );
  }
}
