import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import SwipeableViews from 'react-swipeable-views';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import SpeedIcon from "@mui/icons-material/Speed";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MicIcon from "@mui/icons-material/Mic";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import { IOSSlider } from "../../utils/slider";
import IOSSwitchBtn from "./IOSSwitchBtn";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

const PlayIcon = ({
  scrollTopToBottom,
  speechToggle,
  startSpeechRec,
  audioToggle,
  handleStartAudioRecording,

  videoRecordToggle,
  handleStartVideoRecording,
}) => {
  // Voice Recogination

  const handleStart = () => {
    if (audioToggle) {
      handleStartAudioRecording();
    }
    if (videoRecordToggle) {
      handleStartVideoRecording();
    }
    if (speechToggle) {
      startSpeechRec();
      return;
    }
    scrollTopToBottom();
  };

  return (
    <>
      <img
        src={`assets/images/${
          audioToggle
            ? "mic.png"
            : videoRecordToggle
            ? "startRecording.png"
            : "play.svg"
        }`}
        onClick={() => handleStart()}
      />
    </>
  );
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="model-center-content">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const Modelfootertabs = ({
  handleSeekSpeed,
  seekSpeed,
  handleFontSizeChange,
  fontSize,
  padding,
  handlePaddingChange,
  transparencyValue,
  handleTransparencyChange,
  setTextAlign,
  handleInvertChange,
  handleMirrorChange,
  scrollTopToBottom,
  speechToggle,
  setSpeechToggle,
  startSpeechRec,
  setGetActiveTabs,
  getActiveTabs,
  mirrorToggle,
  invertToggle,
  audioToggle,
  handleAudioToggle,
  handleVideoRecordToggle,
  handleStartAudioRecording,
  videoRecordToggle,
  handleVideoToggle,
  videoToggle,
  handleFullVideoToggle,
  fullVideoToggle,
  handleStartVideoRecording,
  expanded,
  setExpanded,
}) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSpeechToggle = (e) => {
    setSpeechToggle(e.target.checked);
  };

  useEffect(() => {
    setValue(getActiveTabs);
  }, [getActiveTabs]);

  // console.log(speechToggle, "speechToggle");

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon tabs example"
        variant="fullWidth"
      >
        <Tab
          aria-label="A+"
          className="tabs_icons"
          icon={<TextIncreaseIcon />}
          onClick={() => {
            setGetActiveTabs(0);
            setExpanded(true);
          }}
        />
        <Tab
          aria-label="Speed"
          className="tabs_icons"
          icon={<SpeedIcon />}
          onClick={() => {
            setGetActiveTabs(1);
            setExpanded(true);
          }}
        />
        <Tab
          aria-label="Play"
          className="tabs_icons play_pause"
          icon={
            <PlayIcon
              scrollTopToBottom={scrollTopToBottom}
              speechToggle={speechToggle}
              startSpeechRec={startSpeechRec}
              handleAudioToggle={handleAudioToggle}
              audioToggle={audioToggle}
              handleStartAudioRecording={handleStartAudioRecording}
              videoRecordToggle={videoRecordToggle}
              handleStartVideoRecording={handleStartVideoRecording}
            />
          }
        />
        <Tab
          aria-label="camera"
          className="tabs_icons"
          icon={<CameraAltIcon />}
          onClick={() => {
            setGetActiveTabs(3);
            setExpanded(true);
          }}
        />
        <Tab
          aria-label="mic"
          className="tabs_icons"
          icon={<MicIcon />}
          onClick={() => {
            setGetActiveTabs(4);
            setExpanded(true);
          }}
        />
        <span className="toggle-footer-icon">
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </span>
      </Tabs>

      <div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <div className="row">
              <div className="col-lg-12">
                <div className="Model-edit-heading-line">
                  <p className="mb-2">Formatting Options</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="gray-bg-back-side">
                  <div className="row">
                    <div className="col-3">
                      <p className="mb-2 pt-2 pl-1">Text Size</p>
                    </div>
                    <div className="col-9 mt-1">
                      <IOSSlider
                        className=""
                        aria-label="ios slider"
                        // id="scriptZomm"
                        valueLabelDisplay="on"
                        min={25}
                        value={fontSize}
                        max={50}
                        onChange={handleFontSizeChange}
                        step={0.01}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="gray-bg-back-side">
                  <div className="row pt-1">
                    <div className="col-3">
                      <p className="mb-0 pt-1 pl-1">Align</p>
                    </div>
                    <div className="col-3 text-left">
                      <img
                        src="assets/images/text-left-icon.svg"
                        alt=""
                        className="cursor"
                        onClick={() => setTextAlign("left")}
                      />
                    </div>
                    <div className="col-3 text-center">
                      <img
                        src="assets/images/text-center-icon.svg"
                        alt=""
                        className="cursor"
                        onClick={() => setTextAlign("center")}
                      />
                    </div>
                    <div className="col-3 text-right">
                      <img
                        src="assets/images/text-right-icon.svg"
                        alt=""
                        className="cursor"
                        onClick={() => setTextAlign("right")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-8">
                <div className="gray-bg-back-side">
                  <div className="row">
                    <div className="col-3">
                      <p className="mb-2 pt-2 pl-1">Margin</p>
                    </div>
                    <div className="col-9 mt-1">
                      <IOSSlider
                        className=""
                        aria-label="ios slider"
                        // id="scriptZomm"
                        valueLabelDisplay="on"
                        min={10}
                        value={padding}
                        max={200}
                        onChange={handlePaddingChange}
                        step={0.001}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="gray-bg-back-side">
                  <div className="row pt-1">
                    <div className="col-3">
                      <p className="mb-0 pt-1 pl-1">Mirror</p>
                    </div>
                    <div className="col-9 text-right">
                      <IOSSwitchBtn
                        handleToggle={handleMirrorChange}
                        toggle={mirrorToggle}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-8">
                <div className="gray-bg-back-side">
                  <div className="row">
                    <div className="col-3">
                      <p className="mb-2 pt-2 pl-1">Transparency</p>
                    </div>
                    <div className="col-9 mt-1">
                      <IOSSlider
                        className=""
                        aria-label="ios slider"
                        // id="scriptZomm"
                        valueLabelDisplay="on"
                        min={0}
                        value={0 || transparencyValue}
                        max={1}
                        onChange={handleTransparencyChange}
                        step={0.01}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="gray-bg-back-side">
                  <div className="row pt-1">
                    <div className="col-3">
                      <p className="mb-0 pt-1 pl-1">Invert</p>
                    </div>
                    <div className="col-9 text-right">
                      <IOSSwitchBtn
                        handleToggle={handleInvertChange}
                        toggle={invertToggle}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </Collapse>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <div className="row">
              <div className="col-lg-12">
                <div className="Model-edit-heading-line">
                  <p className="mb-2">Scrolling</p>
                </div>
              </div>
            </div>
            <div className="gray-bg-back-side mini-gray">
              <div className="row pt-1 pl-2">
                <div className="col-9">
                  <p className="mb-0 pt-1">Speech Recognition</p>
                </div>
                <div className="col-3 text-right">
                  <IOSSwitchBtn
                    handleToggle={handleSpeechToggle}
                    toggle={speechToggle}
                  />
                </div>
              </div>
            </div>
            <div className="gray-bg-back-side mt-3">
              <div className="row">
                <div className="col-1 text-center pt-2">
                  <img src="assets/images/running.svg" alt="" width={17} />
                </div>
                <div className="col-10 mt-1">
                  <IOSSlider
                    className=""
                    aria-label="ios slider"
                    // id="scriptZomm"
                    valueLabelDisplay="on"
                    min={0.81}
                    value={seekSpeed}
                    max={1.81}
                    onChange={handleSeekSpeed}
                    step={0.001}
                  />
                </div>
                <div className="col-1 text-center pt-2">
                  <img src="assets/images/running_fast.svg" alt="" width={28} />
                </div>
              </div>
            </div>
          </TabPanel>
        </Collapse>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <div className="row">
              <div className="col-lg-12">
                <div className="Model-edit-heading-line">
                  <p className="mb-2">Video</p>
                </div>
              </div>
            </div>
            <div className="gray-bg-back-side mini-gray">
              <div className="row pt-1 pl-2">
                <div className="col-9">
                  <p className="mb-0 pt-1">Record me</p>
                </div>
                <div className="col-3 text-right">
                  <IOSSwitchBtn
                    handleToggle={handleVideoRecordToggle}
                    toggle={videoRecordToggle}
                  />
                </div>
              </div>
            </div>
            <div className="gray-bg-back-side mini-gray mt-2">
              <div className="row pt-1 pl-2">
                <div className="col-9">
                  <p className="mb-0 pt-1">Show me full screen</p>
                </div>
                <div className="col-3 text-right">
                  <IOSSwitchBtn
                    handleToggle={handleFullVideoToggle}
                    toggle={fullVideoToggle}
                  />
                </div>
              </div>
            </div>
            <div className="gray-bg-back-side mini-gray mt-2">
              <div className="row pt-1 pl-2">
                <div className="col-9">
                  <p className="mb-0 pt-1">Show me thumbnail</p>
                </div>
                <div className="col-3 text-right">
                  <IOSSwitchBtn
                    handleToggle={handleVideoToggle}
                    toggle={videoToggle}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </Collapse>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <div className="row">
              <div className="col-lg-12">
                <div className="Model-edit-heading-line">
                  <p className="mb-2">Audio</p>
                </div>
              </div>
            </div>
            <div className="gray-bg-back-side mini-gray mt-2">
              <div className="row pt-1 pl-2">
                <div className="col-9">
                  <p className="mb-0 pt-1">Record Me</p>
                </div>
                <div className="col-3 text-right">
                  <IOSSwitchBtn
                    handleToggle={handleAudioToggle}
                    toggle={audioToggle}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </Collapse>
      </div>
    </>
  );
};
