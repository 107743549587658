import { loaderConstants } from "../actions/constants";

const initialState = {
  loader: false,
};

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case loaderConstants.LOADER_START:
      return {
        loader: true,
      };

    case loaderConstants.LOADER_STOP:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};
