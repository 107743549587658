export const add_story_board = `${process.env.REACT_APP_API_URL}/add_story_board`;
export const delete_story_board = `${process.env.REACT_APP_API_URL}/delete_story_board`;
export const show_folders = `${process.env.REACT_APP_API_URL}/show_folders`;
export const show_story_board = `${process.env.REACT_APP_API_URL}/show_story_board`;
export const add_script_under_folder = `${process.env.REACT_APP_API_URL}/add_script_under_folder`;
export const add_recent_script = `${process.env.REACT_APP_API_URL}/add_recent_script`;
export const show_recent_scripts = `${process.env.REACT_APP_API_URL}/show_recent_scripts`;
export const delete_recent_scripts = `${process.env.REACT_APP_API_URL}/delete_recent_scripts`;
export const show_scripts = `${process.env.REACT_APP_API_URL}/show_scripts`;
export const edit_story_board = `${process.env.REACT_APP_API_URL}/edit_story_board`;
export const add_folders = `${process.env.REACT_APP_API_URL}/add_folders`;

// drag story board API

export const set_story_board_orders = `${process.env.REACT_APP_API_URL}/set-story-board-orders`;
export const story_board_orders = `${process.env.REACT_APP_API_URL}/story-board-orders`;
export const show_single_script = `${process.env.REACT_APP_API_URL}/show_single_script`;

// free trail api
export const addFreeOrdersInDb = `${process.env.REACT_APP_API_URL}/addFreeOrdersInDb`;

// check subscription status
export const check_payment_status = `${process.env.REACT_APP_API_URL}/check_payment_status`;

// payment api's
export const active_plans = `${process.env.REACT_APP_API_URL}/active_plans`;
export const pay_now = `${process.env.REACT_APP_API_URL}/pay_now`;
export const create_customer_subscription = `${process.env.REACT_APP_API_URL}/create_customer_subscription`;

// TEST STRIPE APIS
// export const create_customer_subscription = `${process.env.REACT_APP_API_URL}/create_customer_subscription_test`;
// export const active_plans = `${process.env.REACT_APP_API_URL}/active_plans_test`;
// export const pay_now = `${process.env.REACT_APP_API_URL}/pay_now_test`;

// permanent delete account

export const delete_user = `${process.env.REACT_APP_API_URL}/delete_user`;
export const show_scripts_with_filters = `${process.env.REACT_APP_API_URL}/show_scripts_with_filters`;
export const show_single_folder_with_filters = `${process.env.REACT_APP_API_URL}/show_single_folder_with_filters`;

// setting fetch API

export const get_control_values = `${process.env.REACT_APP_API_URL}/get_control_values`;
export const verify_user_email = `${process.env.REACT_APP_API_URL}/verify_user_email`;

//Create Payment Intent without User Info
export const create_payment_intent_without_userId = `${process.env.REACT_APP_API_URL}/create-payment-intent`;
