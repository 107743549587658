import { toast } from "react-toastify";
import axiosInstance from "../../helpers/axios";
import { check_payment_status } from "../../utils/ApiList";
import { authConstants } from "./constants";
import { settingAction } from "./setting.actions";
import { subscriptionStatus } from "./subscriptionStatus.action";

export const login = (user, planType) => {
  return async (dispatch) => {
    dispatch({
      type: authConstants.LOGIN_REQUEST,
      payload: {
        ...user,
      },
    });

    const res = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/user_login`,
      {
        ...user,
      }
    );

    if (res.status === 200) {
      const { message, data } = res.data;
      // console.log("message", data);
      if (message === "Successfully Login") {
        const userId = data && data.user_id;
        localStorage.setItem("scriptUserId", userId);

        recentScript(userId);

        localStorage.setItem("userId", userId);
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(settingAction());

        await subscriptionStatus();
        const screenWidth = window.screen.width / 3;
        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payload: { userId, data },
        });
        if (planType) {
          window.open(
            "/subscription/checkout",

            "_blank",
            `location=yes,height=750,width=800,top=10, scrollbars=yes,status=yes, left=${screenWidth},  nodeIntegration=no  `
          );
        }
      } else if (message === "Invalid Credentials") {
        toast.warn("Invalid Credentials", {
          hideProgressBar: true,
        });
        // window.location.reload();
        dispatch({
          type: authConstants.LOGIN_FAIL,
          payload: { error: "Something went wrong" },
        });
      }
      // window.location.reload();
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const userId = localStorage.getItem("userId");

    if (userId) {
      const user = JSON.parse(localStorage.getItem("user"));
      recentScript(userId);

      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: { userId, user },
      });
    } else {
      dispatch({
        type: authConstants.LOGIN_FAIL,
        payload: { error: "Failed to login" },
      });
    }
  };
};

export const userlogout = () => {
  return async (dispatch) => {
    localStorage.clear();
    //console.clear();

    dispatch({
      type: authConstants.LOGOUT_REQUEST,
    });
  };
};

const recentScript = async (userId) => {
  await axiosInstance
    .post(
      `${process.env.REACT_APP_API_URL}/show_recent_scripts`,

      { user_id: userId }
    )
    .then((res) => {
      // console.log(res);
    })
    .catch((err) => {});
};
