import React from "react";

function Synonym({ synonym, dicNotFound }) {
  return (
    <div>
      <div className="mt-2">
        <p>Synonym</p>
        <div className="mt-1">
          {synonym.length === 0
            ? null
            : synonym.map((synonym, index) => (
                <div key={index}>
                  <ul className="list-unstyled dictionary">
                    {synonym.words.map((item, index) => (
                      <li key={index}>
                        synonym <span className="synonym ml-3">{item}</span>{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
        </div>
        {dicNotFound ? "Sorry, no results found" : null}
      </div>
    </div>
  );
}

export default Synonym;
