import React, { Component } from "react";
import App from "./App";
import Loader from "react-loader-spinner";

export default class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }
  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    return this.state.isLoading ? (
      <div className="text-center">
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    ) : (
      <div>
        <App />


        {/* <ConvertKit /> */}
      </div>
    );
  }
}
