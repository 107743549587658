import {
  scrollingSpeedConstants,
  updateScriptConstants,
} from "./../actions/constants";

const initialState = {
  scrollValue: 1,
};
const editScriptState = {
  enableEdit: false,
};
const scrollingState = {
  scrollState: false,
};

const scrollSpeedReducer = (state = initialState, action) => {
  switch (action.type) {
    case scrollingSpeedConstants.INCREASE_DECREASE_SPEED:
      return {
        ...state,
        scrollValue: action.payload,
      };

    default:
      return initialState;
  }
};

const enableDisableEditReducer = (state = editScriptState, action) => {
  switch (action.type) {
    case updateScriptConstants.ENABLE_EDIT_SCRIPT:
      return {
        ...state,
        enableEdit: action.payload,
      };

    default:
      return editScriptState;
  }
};

export { scrollSpeedReducer, enableDisableEditReducer };
