import { subscriptionConstants, subscriptionModalConstants } from "./constants";

import axiosInstance from "../../helpers/axios";

export const subscriptionStatus = (user_id) => {
  return async (dispatch) => {
    dispatch({
      type: subscriptionConstants.SUBSCRIPTION_REQUEST,
      payload: {
        loading: true,
      },
    });
    const res = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/check_payment_status`,
      {
        user_id:
          user_id === undefined ? localStorage.getItem("userId") : user_id,
      }
    );

    const { code, data } = res.data;

    if (code && code === 200) {
      dispatch({
        type: subscriptionConstants.SUBSCRIPTION_STATUS,
        payload: {
          subscriptionStatus: data,
          loading: false,
        },
      });
    } else {
      dispatch({
        type: subscriptionConstants.SUBSCRIPTION_FAIL,
        payload: {
          loading: false,
        },
      });
    }
  };
};

export const subscriptionModal = (todo) => {
  return async (dispatch) => {
    dispatch({
      type: subscriptionModalConstants.SUBSCRIPTION_MODAL_SUCCESS,
      payload: {
        ...todo,
      },
    });
  };
};
