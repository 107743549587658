import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import { create_customer_subscription } from "../../utils/ApiList";
import axios from "axios";
import { getOSController } from "../../utils/DetectOS/DetectOs";
import { refreshPageController } from "../../utils/RefreshPage";

export default function RedirectAppStripeCheckoutForm({ subscriptionData }) {
  var currencyFormatter = require("currency-formatter");
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(async ({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");

            await addSubscriptionOrder(paymentIntent, subscriptionData);
            toast.success("Payment succeeded!");

            break;
          case "processing":
            setMessage("Your payment is processing.");
            toast.success("Your payment is processing.");
            break;
          case "requires_payment_method":
            setErrorMessage(
              "Your payment was not successful, please try again."
            );
            toast.error("Your payment was not successful, please try again.");

            break;
          default:
            setErrorMessage("Something went wrong.");
            toast.error("Something went wrong.");
            break;
        }
      });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "https://app.scriptively.com/subscription/checkout",
        return_url:
          "https://app.scriptively.com/app/subscription/checkout/payment",
        // return_url: "http://localhost:3000/app/subscription/checkout/payment",
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setErrorMessage(error.message);
    } else {
      setErrorMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const addSubscriptionOrder = async (data, subscriptionData) => {
    const device_type = getOSController();
    if (data) {
      if (data.status === "succeeded") {
        const res = await axios.post(create_customer_subscription, {
          user_id: subscriptionData.user_id,
          device_type: device_type,
          product_id: subscriptionData.product_id,
          price: subscriptionData.price,
          currency: subscriptionData.currency,
          payment_type: "card",
          pi_id: data && data.id,
          quantity: 1,
          plan_id: subscriptionData.plan_id, // plan_id is price id
        });

        if (res.data.success) {
          setMessage("Your subscription has been created successfully");
          // setTimeout(() => {
          //   refreshPageController();
          // }, 200);
          localStorage.clear();
          window.location.href = "https://scriptively.com/thank-you/";
        } else {
          setErrorMessage(res.data.message);
        }
        console.log(res, "Subscription created successfully");
        return false;
      } else {
        setErrorMessage(data.status);
      }

      // else {
      //   setTimeout(() => {
      //     refreshPageController();
      //   }, 200);
      // }
    }
  };

  return (
    <div>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="pb-2">
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">{message}</Alert>
          </Stack>
        </div>
      )}

      {errorMessage && (
        <div id="payment-message" className="pb-2">
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="error">{errorMessage}</Alert>
          </Stack>
        </div>
      )}
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <div className="mt-3"></div>
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className="pay-btn"
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner">
                Please wait...
              </div>
            ) : (
              <b>
                <span style={{ margin: "0 5px" }}>
                  {currencyFormatter.format(
                    subscriptionData && subscriptionData.price,
                    {
                      code:
                        subscriptionData &&
                        subscriptionData.currency.toUpperCase(),
                    }
                  )}
                </span>
                Pay now
              </b>
            )}
          </span>
        </button>
      </form>
    </div>
  );
}
