import React, { useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

const Forgot = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const forgot = async (e) => {
    e.preventDefault();

    await axios
      .post(`${process.env.REACT_APP_API_URL}/forget_password`, {
        email: email,
      })
      .then(function (response) {

        const message = response.data.message;
        setError(message);

        if (message === "Mail Sent !") {
          setEmail("");
        } else {
          if (message === "This Email is not Exist in the User's List") {
            setEmail("");
          }
        }

        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="container-fluid login-container">
      <div className="row">
        <div className="col-md-6 br-right">
          <div className="login-side">
            <h5>Script, Prompt, and Speak!</h5>
            <img
              src="assets/images/scriptively-speaker.png"
              alt="profile_img"
            // className="img-fluid"
            />
            <h6>Become a better speaker, teacher, and leader.</h6>
          </div>
        </div>
        <div className="col-md-6 pl-0">
          <div className="login-section">
            <div className="login-inner">
              <div className="profile-img">
                <img
                  src="assets/images/scriptively-logo.png"
                  alt="profile_img"
                  className="ml-4"
                />
              </div>
              <div className="container login-form-content">
                <h6>Forgot Password</h6>

                {/* <div className="profile-img text-left">
            <img
              src="assets/images/logo.svg"
              alt="profile_img"
              height="100px"
              width="100px;"
              className="ml-4"
            />
            <h4 className="text-left pt-3 pb-4">Forgot Password</h4>
          </div> */}
                {error === "Mail Sent !" ? (
                  <p className="text-success">{error}</p>
                ) : (
                  <p className="text-danger">{error}</p>
                )}
                <form onSubmit={forgot}>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control custom-input"
                      name="email"
                      placeholder="Enter Your Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block custom-btn border-0"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="back-to-login text-center">
                    <Link to="/">Back to login</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Forgot;
