import axiosInstance from "../../helpers/axios";
import { folderScriptConstants } from "./constants";
export const showAllFolderScripts = (id) => {
  return async (dispatch) => {
    const user_id = localStorage.getItem("userId");
    const res = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/show_single_folder_data`,
      {
        user_id: user_id,
        folder_id: id,
      }
    );
    // console.log(res);
    const msg = res.data.message;
    if (msg === "Folder Data") {
      // //console.clear();
      const arr = res.data.data;
      const folderScriptData =
        arr.length === 0 ? null : arr.map((element) => element);
      dispatch({
        type: folderScriptConstants.FOLDER_RELOAD_SUCCESS,
        payload: { folderScriptData, msg: "", loading: false },
      });
    } else if (msg === "This Folder is Empty Now") {
      // //console.clear();
      dispatch({
        type: folderScriptConstants.FOLDER_RELOAD_SUCCESS,
        payload: {
          folderScriptData: null,
          msg: "This Folder is Empty Now",
          loading: false,
        },
      });
    }
  };
};
