import { userConstants } from "../actions/constants";
const initialState = {
  message: "",
  data: { user_id: "", email: "", Password: "", first_name: "", last_name: "" },
  loading: false
};

export default (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case userConstants.USER_REGISTER_REQUEST:
      state = {
        ...state,
        loading: true
      };
      break;
    case userConstants.USER_REGISTER_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        loading: false
      };
      break;
    case userConstants.USER_REGISTER_FAIL:
      state = {
        ...state,
        loading: false,
        message: action.payload.message
      };
      break;
    default:
  }

  return state;
};
