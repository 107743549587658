import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Recaptchaa from 'react-google-invisible-recaptcha';

export const ReCaptcha = () => {
  const [verify, setVerify] = useState(false);
  const refRecaptcha = useRef()

  const onChange = (e) => {
    e.preventDefault();

    console.log("Captcha value:", e);
    let value = ""

    console.log(refRecaptcha, "refRecaptcha")
    if ('' == value) {
      alert('Validation failed! Input cannot be empty.');
      refRecaptcha.current.reset();

    } else {
      refRecaptcha.current.execute();
    }
  };

  return (
    <div>



      <button className="btn btn-primary" onClick={onChange}>
        Submit
      </button>
    </div>
  );
};


// class ReCaptcha extends React.Component {
//   constructor(props) {
//     console.log(props.data)
//     super(props);
//     this.state = { value: '', };
//     this.userData = props.data;
//     this.onSubmit = this.onSubmit.bind(this);
//     this.onResolved = this.onResolved.bind(this);
//   }
//   render() {

//     return (
//       <div>
//         <input
//           type="text"
//           value={this.state.value}
//           onChange={event => this.setState({ value: event.target.value })} />
//         {/* <button >Submit</button> */}
//         <div className="form-group">
//           <button
//             type="submit"
//             onClick={this.onSubmit}
//             className="btn btn-primary btn-lg btn-block custom-btn border-0 btn-login"
//           >
//             Login
//           </button>
//         </div>
//         <Recaptchaa
//           ref={ref => this.recaptcha = ref}
//           sitekey="6LevPJ0hAAAAAGeqGlYrC11wj06tzOdvW93hVlsv"
//           onResolved={this.onResolved} />
//       </div>
//     );
//   }
//   onSubmit() {
//     if ('' == this.state.value) {
//       alert('Validation failed! Input cannot be empty.');
//       this.recaptcha.reset();
//     } else {
//       this.recaptcha.execute();
//     }
//   }
//   onResolved() {
//     console.log(this.recaptcha.getResponse());
//     alert('Recaptcha resolved with response: ' + this.recaptcha.getResponse());
//   }
// }