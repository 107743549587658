import React, { useRef, useState, useEffect } from "react";
import $ from "jquery";
import ImportDropDownMenuComponent from "./DropDownMenu/ImportDropDownMenuComponent";
import PrintEmailMenuComponent from "./DropDownMenu/PrintEmailMenuComponent";

import { useReactToPrint } from "react-to-print";
import EmailMenu from "./DropDownMenu/EmailMenu";
import ColorPicker from "./ColorPicker/ColorPicker";
class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="print-text">
        <div className="mt-3"> </div>
        <p
          dangerouslySetInnerHTML={{
            __html: this.props.attrText,
          }}
        ></p>
      </div>
    );
  }
}

function NewToolBar({
  googleDriveSendData,
  showAllScript,

  updateScript,
  setToggleSelect,
  setLoader,
  setEmailAndPrintAction,
  emailAndPrintAction,

  setScrAlignment,
  scrEditTextSize,
  searchScript,
  changeSearchText,
  setBoardAction,
  dictToggle,
  setDictToggle,
  colorAction,
  setColorAction,
}) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [attributedText, setSetAttributedText] = useState("");

  const italicText = () => {
    setColorAction(false);
    document.execCommand("italic", false, null);
  };

  const underlineText = () => {
    setColorAction(false);
    document.execCommand("underline", false, null);
  };
  const boldText = () => {
    document.execCommand("bold", false, null);
    setColorAction(false);
  };

  function changeFont(e) {
    const fSize = e.target.value;
    document.execCommand("fontSize", false, "7");
    var fontElements = document.getElementsByTagName("font");
    for (var i = 0, len = fontElements.length; i < len; ++i) {
      if (fontElements[i].size == "7") {
        fontElements[i].removeAttribute("size");
        fontElements[i].style.fontSize = `${fSize}px`;
      }
    }
  }

  useEffect(() => {
    const attrData = document.getElementById("main_script").innerHTML;

    const scrAttrText = attrData.split("true").join("false");
    setSetAttributedText(scrAttrText);
  });

  return (
    <div className="custom-toolbar">
      <div className="d-none">
        <ComponentToPrint
          ref={componentRef}
          className="d-none"
          attrText={attributedText}
        />
      </div>
      <div
        className="row p-2"
        style={{
          borderBottom: "1px solid #e5e5e5",
          backgroundColor: "#F9F9F9",
        }}
      >
        <div className="col-lg-4 col-10 ">
          {/* <button className="sb-searchs"> */}
          <div
            // id="sb-search"
            className=""
            // onClick={toggleSearchShow}
          >
            <form className="search-top">
              <span
                className="sb-icon-searchs"
                onClick={(e) => {
                  if (!$(".sb-search").hasClass("sb-search-open")) {
                    changeSearchText("");
                  }
                }}
              >
                <img
                  src="assets/images/search.svg"
                  alt=""
                  srcSet=""
                  width="18px"
                />
              </span>
              <input
                className="sb-search-inputs  border-0"
                // onKeyUp="buttonUp();"
                placeholder="Search..."
                type="search"
                name="search"
                id="search"
                value={searchScript}
                onChange={(e) => {
                  changeSearchText(e.target.value);
                }}
              />
            </form>
          </div>
          {/* </button> */}
        </div>
        <div className="col-lg-1 col-2 text-right">
          <button className="edit-btn import-drive-files">
            <ImportDropDownMenuComponent
              googleDriveSendData={googleDriveSendData}
              showAllScript={showAllScript}
              setToggleSelect={setToggleSelect}
              setLoader={setLoader}
              setEmailAndPrintAction={setEmailAndPrintAction}
              setBoardAction={setBoardAction}
            />
          </button>
        </div>
        <div className="col-lg-7">
          <select
            className="form-select form-select-sm select-option-custom mr-2"
            aria-label=".form-select-sm example"
            onChange={(e) => {
              document.execCommand("fontName", false, e.target.value);
              setColorAction(false);
            }}
          >
            <option value="Font family" className="d-none">
              Arial
            </option>
            <option value="Arial">Arial</option>
            <option value="Georgia">Georgia</option>
            <option value="Tahoma">Tahoma</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Verdana">Verdana</option>
            <option value="Impact">Impact</option>
            <option value="Courier New">Courier New</option>
          </select>
          <select
            className="form-select form-select-sm select-option-custom font-size-options"
            aria-label=".form-select-sm example"
            onChange={(e) => {
              changeFont(e);

              setColorAction(false);
            }}
            onBlur={updateScript}
          >
            <option value="Font Size" className="d-none">
              18
            </option>
            <option value="16">16</option>
            <option value="18">18</option>
            <option value="20">20</option>
            <option value="22">22</option>
            <option value="24">24</option>
            <option value="26">26</option>
            <option value="28">28</option>
            <option value="30">30</option>
            <option value="32">32</option>
            <option value="34">34</option>
            <option value="36">36</option>
            <option value="38">38</option>
            <option value="40">40</option>
          </select>

          <div className="btn-group">
            <button className="dropdown-toggle custom-color pr-0">
              <ColorPicker
                colorAction={colorAction}
                setColorAction={setColorAction}
              />
            </button>
          </div>

          <div className="btn-group " onClick={() => setColorAction(false)}>
            <button
              className="dropdown-toggle custom-color"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>
                {" "}
                <img
                  src="assets/images/font-bg-color.svg"
                  alt=""
                  srcSet=""
                  width="17px"
                  onClick={() => setColorAction(false)}
                />
              </span>
              {/* <i className="fa fa-angle-down ml-2"></i> */}
            </button>
            <div className="dropdown-menu">
              <div className="color-menu">
                <button
                  className="dropdown-item highlight1 ml-2"
                  onClick={() =>
                    document.execCommand("hiLiteColor", false, "#ffc107")
                  }
                  onBlur={updateScript}
                ></button>
                <button
                  className="dropdown-item highlight2 ml-2"
                  onClick={() =>
                    document.execCommand("hiLiteColor", false, "#00ff00")
                  }
                ></button>
                <button className="dropdown-item highlight3 ml-1">
                  <span
                    className="no_color"
                    onClick={() =>
                      document.execCommand("hiLiteColor", false, "#fff")
                    }
                  >
                    {" "}
                    No color{" "}
                  </span>
                </button>
              </div>
            </div>
          </div>

          <button
            className="edit-btn"
            id="bold"
            title="Bold (Ctrl+B)"
            onClick={boldText}
          >
            <img src="assets/images/bold.svg" alt="" srcSet="" width="24px" />
          </button>
          <button
            className="edit-btn"
            id="italic"
            onClick={italicText}
            title="Italic (Ctrl+I)"
          >
            <img
              src="assets/images/text-italic.svg"
              alt=""
              srcSet=""
              width="24px"
            />
          </button>
          <button
            className="edit-btn"
            id="underline"
            onClick={underlineText}
            title="Underline (Ctrl+U)"
          >
            <img
              src="assets/images/underline.svg"
              alt=""
              srcSet=""
              width="24px"
            />
          </button>

          <button
            className="edit-btn"
            id="align-left"
            title="Left"
            onClick={() => {
              setScrAlignment("left");

              setColorAction(false);
            }}
          >
            <img
              src="assets/images/align-left.svg"
              alt=""
              srcSet=""
              width="24px"
            />
          </button>
          <button
            className="edit-btn"
            id="align-center"
            title="Center"
            onClick={() => {
              setScrAlignment("center");
              setColorAction(false);
            }}
          >
            <img
              src="assets/images/align-center.svg"
              alt=""
              srcSet=""
              width="24px"
            />
          </button>
          <button
            className="edit-btn"
            id="align-right"
            title="Right"
            onClick={() => {
              setScrAlignment("right");
              setColorAction(false);
            }}
          >
            <img
              src="assets/images/align-right.svg"
              alt=""
              srcSet=""
              width="24px"
            />
          </button>

          <button
            className="edit-btn"
            onClick={() => {
              dictToggle ? setDictToggle(false) : setDictToggle(true);
              setColorAction(false);
            }}
          >
            <img
              src="assets/images/dictionary-new.svg"
              alt=""
              srcSet=""
              width="24px"
            />
          </button>
          <button className="edit-btn">
            <PrintEmailMenuComponent
              handlePrint={handlePrint}
              emailAndPrintAction={emailAndPrintAction}
            />
          </button>
          <button className="edit-btn">
            <EmailMenu emailAndPrintAction={emailAndPrintAction} />
          </button>
          {/* 
          <div className="dropdown-custom">
            <span>
              <img
                src="assets/images/O-NOPHOTO.svg"
                className="pro-img"
                alt="pro"
              />
            </span>
            <div className="dropdown-content-custom">
              <Link to="/profile">Profile</Link>
              <hr />
              <div className="logout-btn">
                <LogoutModal />
              </div>
            </div>
          </div> */}

          {/* <button className="edit-btn">
        <ImportDropDownMenuComponent
          className="edit-btn"
          googleDriveSendData={googleDriveSendData}
          showAllScript={showAllScript}
          setToggleSelect={setToggleSelect}
          setLoader={setLoader}
          setEmailAndPrintAction={setEmailAndPrintAction}
        />
      </button> */}

          {/* {dictToggle && <Dictionary />} */}
        </div>
      </div>
    </div>
  );
}

export default NewToolBar;
