import React, { useState, useEffect } from "react";
import logo from "./images/logo.png";
import check from "./images/check.svg";
import axios from "axios";
import { active_plans } from "../../utils/ApiList";
import LoadingOverlay from "react-loading-overlay";
import closeCircle from "./images/close-circle.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { refreshPageController } from "../../utils/RefreshPage";
import { useHistory, useLocation } from "react-router-dom";
import "./Subscription.css";
import { subscriptionStatus } from "../../services/actions";
const SubscriptionComponent = () => {
  var currencyFormatter = require("currency-formatter");
  const screenWidth = window.screen.width / 3;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [card, setCard] = useState("0");
  const search = useLocation().search;
  const planType = new URLSearchParams(search).get("plan");

  let planArr = ["monthly", "6months", "yearly"];

  const [plan, setPlan] = useState(null);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [loader, setLoader] = useState(false);
  const globalState = useSelector((state) => state);
  let isSubStatus =
    globalState &&
    globalState.subscriptionStatus &&
    globalState.subscriptionStatus.subscriptionStatus &&
    globalState.subscriptionStatus.subscriptionStatus &&
    globalState.subscriptionStatus.subscriptionStatus.payment_status_code;

  let isSubscriptionPremium = isSubStatus === "1" ? true : false;

  React.useEffect(() => {
    dispatch(subscriptionStatus());
  }, []);

  useEffect(() => {
    localStorage.setItem("profilePage", true);

    const getPlanList = async () => {
      setLoader(true);

      const { data } = await axios.get(active_plans);
      if (data.success == 1) {
        setSubscriptionList(data.data);

        if (planType) {
          localStorage.removeItem("userId");

          let index = planArr.indexOf(planType);
          if (index >= 0) {
            setCard(index.toString());
            setPlan(data.data[index]);

            localStorage.setItem(
              "subscriptionData",
              JSON.stringify(data.data[index])
            );

            let userId = localStorage.getItem("userId");
            setLoader(false);

            if (!userId) {
              // history.push("/login?plan=" + planType);
              history.push("/app/subscription/checkout");
            }

            setTimeout(() => {
              if (isSubscriptionPremium === true) {
                toast.info("You are a premium user.");
              } else {
                let userId = localStorage.getItem("userId");

                if (userId) {
                  // history.push("/subscription/checkout");
                  history.push("/app/subscription/checkout");
                } else {
                  // history.push("/login?plan=" + planType);
                  history.push("/app/subscription/checkout");
                }
              }
            }, 1000);
          } else {
            setPlan(data.data[0]);
          }
        } else {
          setPlan(data.data[0]);
        }
        // //console.clear();
      } else {
        toast.error(data.message);
      }
      setLoader(false);
    };

    getPlanList();
  }, []);

  if (planType) {
    return (
      <div id="subscription-modal">
        <LoadingOverlay active={loader} spinner></LoadingOverlay>
        Please wait...
      </div>
    );
  }
  return (
    <div id="subscription-modal">
      <LoadingOverlay active={loader} spinner>
        <div className="pricing">
          <div className="container">
            <div className="close-btn" style={{ cursor: "pointer", zIndex: 1 }}>
              {location.pathname !== "/subscription" && (
                <a href="#/">
                  <img
                    src={closeCircle}
                    className="img-fluid close-circle"
                    onClick={() => refreshPageController()}
                  />
                </a>
              )}
            </div>
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="logo-top mt-5">
                  <img src={logo} className="img-fluid" />
                  <h2 className="pt-3">Get Scriptively Unlimited</h2>
                  <p>
                    Everything you need to become a <br></br>better speaker,
                    teacher, and leader.
                  </p>
                </div>
              </div>
            </div>

            <div className="custom-text-sub">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-3 col-6 text-left">
                  <div className="sub-title-tick">
                    <p>
                      <img src={check} className="img-fluid img_right_tick" />{" "}
                      Scriptwriter
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-6 text-left">
                  <div className="sub-title-tick">
                    <p>
                      <img src={check} className="img-fluid img_right_tick" />{" "}
                      Storyboard Editor
                    </p>
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-3 col-6 text-left">
                  <div className="sub-title-tick">
                    <p>
                      <img src={check} className="img-fluid img_right_tick" />{" "}
                      Folder Management
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-6 text-left">
                  <div className="sub-title-tick">
                    <p>
                      <img src={check} className="img-fluid img_right_tick" />{" "}
                      Cloud Sync/Backup
                    </p>
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-3 col-6 text-left">
                  <div className="sub-title-tick">
                    <p>
                      <img src={check} className="img-fluid img_right_tick" />{" "}
                      Video Teleprompter
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-6 text-left">
                  <div className="sub-title-tick">
                    <p>
                      <img src={check} className="img-fluid img_right_tick" />{" "}
                      Speech Recognition
                    </p>
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-3 col-6 text-left">
                  <div className="sub-title-tick">
                    <p>
                      <img src={check} className="img-fluid img_right_tick" />{" "}
                      Mirrored/Inverted Text
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-6 text-left">
                  <div className="sub-title-tick">
                    <p>
                      <img src={check} className="img-fluid img_right_tick" />{" "}
                      Audio/Video Recorder
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="price-sec-wrap">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8 offset-md-2">
                        <div className="main-heading">
                          Select a Subscription
                        </div>
                      </div>
                    </div>
                    <div className="row custom-card">
                      {subscriptionList &&
                        subscriptionList.map((plan, index) => (
                          <div className="col-lg-4 col" key={index}>
                            <div
                              className={
                                parseInt(card) == index
                                  ? "price-box active"
                                  : "price-box-inactive"
                              }
                            >
                              <div className="">
                                <div className="price-info">
                                  {plan.billing_period}
                                </div>
                                <div className="price">
                                  {currencyFormatter.format(
                                    plan && plan.price,
                                    {
                                      code: plan && plan.currency.toUpperCase(),
                                    }
                                  )}
                                </div>
                              </div>
                              <div
                                className="info"
                                onClick={() => {
                                  setCard(index);
                                  setPlan(plan && plan);
                                }}
                              >
                                <a className="plan-btn">Select</a>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="bottom-text">
                          <h4 className="pt-5">
                            <b>
                              <span style={{ margin: "0 5px" }}>
                                {currencyFormatter.format(plan && plan.price, {
                                  code: plan && plan.currency.toUpperCase(),
                                })}
                              </span>{" "}
                              <span className="pr-1">
                                {" "}
                                {plan && plan.currency.toUpperCase()}
                              </span>
                              per {plan && plan.billing_period}{" "}
                            </b>
                          </h4>

                          {isSubscriptionPremium === true ? (
                            <div
                              className="info"
                              onClick={() => {
                                toast.info("You are a premium user.");
                              }}
                            >
                              <span className="plan-btn subscribed">
                                Subscribed
                              </span>
                            </div>
                          ) : isSubStatus === "2" ? (
                            <div
                              className="info"
                              onClick={() => {
                                localStorage.setItem(
                                  "subscriptionData",
                                  JSON.stringify(plan && plan)
                                );
                                window.open(
                                  "/subscription/checkout",

                                  "_blank",
                                  `location=yes,height=750,width=800,top=10, scrollbars=yes,status=yes, left=${screenWidth},  nodeIntegration=no  `
                                );
                              }}
                            >
                              <span className="plan-btn subscription">
                                Subscribe
                              </span>
                            </div>
                          ) : location.pathname === "/subscription" ? (
                            <div
                              className="info"
                              onClick={() => {
                                localStorage.setItem(
                                  "subscriptionData",
                                  JSON.stringify(plan && plan)
                                );
                              }}
                            >
                              <a
                                href="/subscription/checkout"
                                className="plan-btn subscription"
                              >
                                Subscribe
                              </a>
                            </div>
                          ) : (
                            <div
                              className="info"
                              onClick={() => {
                                localStorage.setItem(
                                  "subscriptionData",
                                  JSON.stringify(plan && plan)
                                );
                                window.open(
                                  "/subscription/checkout",

                                  "_blank",
                                  `location=yes,height=750,width=800,top=10, scrollbars=yes,status=yes, left=${screenWidth},  nodeIntegration=no  `
                                );
                              }}
                            >
                              <span className="plan-btn subscription">
                                Subscribe
                              </span>
                            </div>
                          )}

                          <p>Recurring billing | Cancel anytime</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="gray-box">
                          <p>
                            Payment will be automatically charged to your credit
                            card account at confirmation of purchase.
                            Subscriptions automatically renew unless canceled at
                            least 24 hours before the end of the current period.
                          </p>
                          <div className="text-center">
                            {/* <a href="#">Privacy Policy</a>
                            <span className="pl-2 pr-2">|</span>
                            <a href="#">Terms of Use</a>
                            <span className="pl-2 pr-2">|</span> */}
                            {/* <a href="#">Restore Purchase</a> */}

                            <a
                              href="https://scriptively.com/privacy/"
                              target="_blank"
                              className="link_color terms ml-2"
                            >
                              Privacy Policy
                            </a>
                            <span className="pl-2 pr-2">|</span>

                            <a
                              href="https://scriptively.com/tou/"
                              target="_blank"
                              className="link_color terms"
                            >
                              Terms of Use
                            </a>
                            <span className="pl-2 pr-2">|</span>
                            <a
                              onClick={() =>
                                window.open(
                                  "/restorePayment",
                                  "_blank",
                                  `location=yes,height=600,width=850,scrollbars=yes,status=yes, top=100, left=${screenWidth},  margin=0 auto,  frame=false,nodeIntegration=no`
                                )
                              }
                              className="link_color terms"
                            >
                              Restore Purchase
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer className="alert-msg" autoClose={3000} />
      </LoadingOverlay>
    </div>
  );
};

export default SubscriptionComponent;
