import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import ByteConverter from "byte-converter-react";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import "./AllScriptContainer.css";
import { showAllFolderScripts, showAllScripts } from "../services/actions";
import { toast } from "react-toastify";

import AlertModal from "../middlaware/AlertModal";
import { toastOptions } from "../utils/ToastAlert";
import {
  deleteRecentScriptController,
  showRecentScriptsController,
} from "../utils/DashboardController";
import { updateScriptConstants } from "../services/actions/constants";

function AllScriptContainer({
  selectActive,

  emptyError,
  allScript,
  scriptHandleSelect,

  scriptView,
  scriptSelection,
  mscript,
  checked,

  setLoader,

  folderId,

  folderAction,
  folderAllScripts,
  setRecentScrList,
  show_script_id,
  setShowScriptTitle,
  setShowScriptAtrrText,
  setStoryBoard2,
  setStb,
  setScriptImage,
  setEditAction,
  setScriptTitle,
  setScriptPara,
  setShowScriptId,
  setToggleSelect,
  setStoryBoardHeading,
  currentScriptId,
  googleDriveSendData,
  storyboardFun,
}) {
  const user_id = localStorage.getItem("userId");

  const [contextId, setContextId] = useState(null);
  // const regex = /style/gi;

  const dispatch = useDispatch();

  const deleteScript = async () => {
    setLoader(true);

    //delete scripts

    if (folderId) {
      // window.open("exit.html", "Thanks for Visiting!");

      try {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/delete_relation`, {
            user_id,
            relation_id: contextId.relation_id,
            folder_id: folderId,
          })
          .then((res) => {
            const msg = res.data.message;
            if (msg === "Script Relation Deleted Successfully") {
              if (
                show_script_id === contextId.id ||
                currentScriptId === contextId.id
              ) {
                setShowScriptTitle("");
                document.getElementById("main_script").innerHTML = "";
                setShowScriptAtrrText("");
                setStoryBoard2([]);
                setStb([]);
                setScriptImage("");
                setStoryBoardHeading(false);
                setEditAction(false);
                setScriptTitle("");
                setScriptPara("");
                setShowScriptId("");
              }
              dispatch(showAllFolderScripts(folderId));
              toast.success(
                "Script has been deleted successfully",
                toastOptions
              );
              // dispatch(showAllScripts());
            }
            setLoader(false);
          });
        // dispatch(showAllScripts());
      } catch (error) {
        setLoader(false);
      }
    } else {
      try {
        const id = contextId.id;
        await axios
          .post(`${process.env.REACT_APP_API_URL}/delete_script`, {
            user_id,
            script_id: id,
          })
          .then(async (res) => {
            const msg = res.data.message;
            if (msg === "Script Deleted Successfully") {
              if (
                show_script_id === contextId.id ||
                currentScriptId === contextId.id
              ) {
                document.getElementById("main_script").innerHTML = "";
                setShowScriptTitle("");
                setShowScriptAtrrText("");
                setStoryBoard2([]);
                setStb([]);
                setScriptImage("");
                setStoryBoardHeading(false);
                setEditAction(false);
                setScriptTitle("");
                setScriptPara("");
                setShowScriptId("");
              }
              dispatch(showAllScripts());

              toast.success(
                "Script has been deleted successfully",
                toastOptions
              );

              await deleteRecentScriptController(id, setToggleSelect);
              await showRecentScriptsController(
                setRecentScrList,
                googleDriveSendData,
                storyboardFun
              );

              setLoader(false);
            } else {
              setLoader(false);
            }
          });
      } catch (error) {
        setLoader(false);
      }
    }
  };

  function WhichButton(event, scripts) {
    setContextId(scripts);
  }

  return (
    <div>
      <div className="nav nav-tabs nav-fill custom-script">
        <>
          {folderAction ? (
            <ul className="list-group " style={{ marginRight: "-10px" }}>
              {
                allScript && allScript === null
                  ? null
                  : allScript &&
                    allScript.map((script, index) => (
                      <ScriptContainer
                        key={index}
                        id="scriptSingleItem"
                        onMouseDown={(e) => WhichButton(e, script)}
                        style={script.searchNotfound ? { display: "none" } : {}}
                      >
                        <ContextMenuTrigger id="contextmenu">
                          <li
                            style={{
                              listStyle: "none",
                              marginBottom: "0px",
                            }}
                            key={index}
                            className="list-group-item list-ui-custom-pane"
                          >
                            <div className="row">
                              <div className="col-lg-7 col-6">
                                <h5
                                  style={{
                                    overflow: "hidden",
                                    height: "20px",
                                  }}
                                  onClick={() => scriptHandleSelect(script)}
                                >
                                  {script.t ||
                                    script.scrTitle ||
                                    scriptView.scrTitle}
                                </h5>
                              </div>
                              <div
                                className="col-lg-3 col-3 text-center"
                                // style={{ display: "contents" }}
                              >
                                <p className="date-custom mb-0 ">
                                  {script && script.scrText.length > 1024000 ? (
                                    <>
                                      <ByteConverter
                                        useSI
                                        inUnit="KB"
                                        outUnit="MB"
                                      >
                                        {script.scrText.length}
                                      </ByteConverter>
                                      <span> MB</span>
                                    </>
                                  ) : (
                                    <>
                                      {script.scrText.length < 1024 ? (
                                        <>
                                          {Math.round(
                                            parseInt(script.scrText.length)
                                          )}

                                          <span> B</span>
                                        </>
                                      ) : (
                                        <>
                                          <ByteConverter
                                            useSI
                                            inUnit="B"
                                            outUnit="KB"
                                          >
                                            {Math.round(
                                              parseInt(script.scrText.length)
                                            )}
                                          </ByteConverter>
                                          <span> KB</span>
                                        </>
                                      )}
                                    </>
                                  )}
                                  {/* {script?.txt.length > 1024000 ? (
                                    <>
                                      <ByteConverter
                                        useSI
                                        inUnit="KB"
                                        outUnit="MB"
                                      >
                                        {script?.txt.length}
                                      </ByteConverter>
                                      <span> MB</span>
                                    </>
                                  ) : (
                                    <>
                                      {script?.txt.length < 1024 ? (
                                        <>
                                          {Math.round(
                                            parseInt(script.txt.length)
                                          )}

                                          <span> B</span>
                                        </>
                                      ) : (
                                        <>
                                          <ByteConverter
                                            useSI
                                            inUnit="B"
                                            outUnit="KB"
                                          >
                                            {Math.round(
                                              parseInt(script.txt.length)
                                            )}
                                          </ByteConverter>
                                          <span> KB</span>
                                        </>
                                      )}
                                    </>
                                  )} */}
                                </p>
                              </div>
                              <div className="col-lg-2 col-3 text-center">
                                <p className="date-custom mb-0">
                                  {script.created_at.slice(0, 9)}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-10 col-10 ">
                                <p
                                  className="mb-0 script__container__paragraph"
                                  onClick={() => {
                                    scriptHandleSelect(script);
                                  }}
                                >
                                  {/* please don't remove this spaces */}

                                  {(
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          script.scrAttrText === undefined
                                            ? script.scrAttrText
                                            : script.scrAttrText
                                                .replace(
                                                  /$|background-color|\^/gi,
                                                  ""
                                                )
                                                .replace(/$|font-size|\^/gi, "")
                                                .replace(/$|size|\^/gi, "")

                                                .replace(/$|face|\^/gi, "")
                                                .replace(/$|color|\^/gi, "")
                                                .replace(/$|s1|\^/gi, "")
                                                .replace(/$|p1|\^/gi, "")
                                                .replace(/$|p2|\^/gi, "")
                                                .replace(/$|br|\^/gi, "")
                                                .replace(/$|s2|\^/gi, ""),
                                        // .replace(/<(.|\n)*?>/gi, ""),
                                      }}
                                    ></span>
                                  ) || scriptView.scrText}
                                </p>
                              </div>
                              <div className="col-lg-2 col-2 text-right d-flex">
                                {scriptSelection && (
                                  <input
                                    type="checkbox"
                                    name="scriptInput"
                                    value={script.id}
                                    className="script "
                                    onClick={() => mscript(script.id)}
                                    defaultChecked={checked}
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      right: "0px",
                                      visibility: "visible",
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </li>
                          <div className="script_border"></div>
                        </ContextMenuTrigger>
                      </ScriptContainer>
                    ))
                // : "loading..."
              }
            </ul>
          ) : (
            // folder script container
            <>
              <ul
                className="list-group folder_scripts_container"
                style={{ marginRight: "-10px" }}
              >
                {
                  folderAllScripts && folderAllScripts === null ? (
                    <>
                      {" "}
                      {selectActive ? null : (
                        <h6 className="ml-2 mt-4">{emptyError}</h6>
                      )}
                    </>
                  ) : (
                    folderAllScripts &&
                    folderAllScripts.map((script, index) => (
                      <ScriptContainer
                        key={index}
                        // className="border-bottom"
                        id="scriptSingleItem"
                        onMouseDown={(e) => WhichButton(e, script)}
                        className=" list-ui-custom-pane "
                        style={script.searchNotfound ? { display: "none" } : {}}
                      >
                        <ContextMenuTrigger id="contextmenu">
                          <li
                            style={{
                              listStyle: "none",
                              marginBottom: "0px",
                            }}
                            key={index}
                            className="list-group-item list-ui-custom-pane "
                            onClick={() => scriptHandleSelect(script)}
                          >
                            <div
                              className="row"
                              style={{
                                height: "90px",
                                marginBottom: "-20px",
                              }}
                            >
                              <div className="col-lg-7">
                                <h5
                                  style={{
                                    overflow: "hidden",
                                    height: "20px",
                                  }}
                                >
                                  {script.t ||
                                    script.scrTitle ||
                                    scriptView.scrTitle}
                                </h5>
                              </div>
                              <div className="col-lg-3 ">
                                <p
                                  className="date-custom mb-0"
                                  style={{
                                    textAlign: "center",
                                    // display: "inline-flex",
                                  }}
                                >
                                  {script && script.scrText.length > 1024000 ? (
                                    <>
                                      <ByteConverter
                                        useSI
                                        inUnit="KB"
                                        outUnit="MB"
                                      >
                                        {script.scrText.length}
                                      </ByteConverter>
                                      <span> MB</span>
                                    </>
                                  ) : (
                                    <>
                                      {script.scrText.length < 1024 ? (
                                        <>
                                          {Math.round(
                                            parseInt(script.scrText.length)
                                          )}

                                          <span> B</span>
                                        </>
                                      ) : (
                                        <>
                                          <ByteConverter
                                            useSI
                                            inUnit="B"
                                            outUnit="KB"
                                          >
                                            {Math.round(
                                              parseInt(script.scrText.length)
                                            )}
                                          </ByteConverter>
                                          <span> KB</span>
                                        </>
                                      )}
                                    </>
                                  )}
                                </p>
                              </div>
                              <div className="col-lg-2 text-center">
                                <p className="date-custom mb-0">
                                  {script.created_at.slice(0, 9)}
                                </p>
                              </div>

                              <div
                                className="col-lg-12 col-10"
                                style={{ bottom: "8px" }}
                                onClick={() => scriptHandleSelect(script)}
                              >
                                <p className="mb-0 script__container__paragraph">
                                  {(
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          script.scrAttrText === undefined
                                            ? script.scrAttrText
                                            : script.scrAttrText
                                                .replace(
                                                  /$|background-color|\^/gi,
                                                  ""
                                                )
                                                .replace(/$|font-size|\^/gi, "")
                                                .replace(/$|size|\^/gi, "")

                                                .replace(/$|face|\^/gi, "")
                                                .replace(/$|color|\^/gi, "")
                                                .replace(/$|s1|\^/gi, "")
                                                .replace(/$|p1|\^/gi, "")
                                                .replace(/$|p2|\^/gi, "")
                                                .replace(/$|br|\^/gi, "")
                                                .replace(/$|s2|\^/gi, ""),
                                        // .replace(/$|style|\^/gi, ""),
                                        // .replace(/<(.|\n)*?>/gi, ""),
                                      }}
                                    ></span>
                                  ) || scriptView.scrText}
                                </p>
                              </div>
                            </div>
                          </li>
                          <div className="script_border"></div>
                        </ContextMenuTrigger>
                      </ScriptContainer>
                    ))
                  )
                  // : "loading..."
                }
              </ul>
            </>
          )}

          <ContextMenu id="contextmenu">
            <MenuItem>
              <AlertModal deleteScr={deleteScript} />
            </MenuItem>
          </ContextMenu>
        </>
      </div>
    </div>
  );
}

const ScriptContainer = styled.div``;
export default AllScriptContainer;
