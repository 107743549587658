import { RecordRTCPromisesHandler } from "recordrtc";
import fixWebmDuration from "webm-duration-fix";

let recorder = null;
let recordStream = null;
const mimeType = "video/webm;codecs=vp9";

const makeRandomName = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
// checking mediaDevices and startRecording
const startVideoRecording = async () => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    console.log("This browser does not support the API yet");
    alert("This browser does not support the mediaDevices yet");
    return;
  }
  let stream = await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: true,
  });
  recordStream = stream;

  recorder = new RecordRTCPromisesHandler(stream, {
    type: "video",
    mimeType: "video/webm;codecs=vp8", // default windows player supported

    minWidth: 1920,
    minHeight: 1080,
    video: { width: { exact: 1920 }, height: { exact: 1080 } },
    aspectRatio: {
      exact: 1920 / 1080,
    },
  });

  recorder.startRecording();
  return { recordingStart: true };
};
// checking mediaDevices and startRecording
const startAudioRecording = async () => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    console.log("This browser does not support the API yet");
    alert("This browser does not support the mediaDevices yet");
    return;
  }
  let stream = await navigator.mediaDevices.getUserMedia({
    audio: true,
  });
  recordStream = stream;

  recorder = new RecordRTCPromisesHandler(stream, {
    type: `audio/mp3`,
    mimeType: `audio/webm`,
  });

  recorder.startRecording();
  return { recordingStart: true };
};

const stopAudioRecording = async () => {
  await recorder.stopRecording();

  let fileBlob = await recorder.getBlob();
  // let url = await recorder.getDataURL()

  stopMediaStream();
  //   recorder?.recordRTC?.reset();

  const blob = await fixWebmDuration(fileBlob, { mimetype: `audio/mp3` });
  let url = URL.createObjectURL(blob);

  return { url, blob };
  //   console.log(blob, 'blob')
};

const stopVideoRecording = async () => {
  await recorder.stopRecording();

  let fileBlob = await recorder.getBlob();
  // let url = await recorder.getDataURL()

  stopMediaStream();
  // recorder?.recordRTC?.reset();

  const blob = await fixWebmDuration(fileBlob, { type: mimeType });
  let url = URL.createObjectURL(blob);

  return { url, blob };
  //   console.log(blob, 'blob')
};

// Stop the mediaDevices access control after use

const stopMediaStream = async () => {
  recordStream.getAudioTracks().forEach(function (track) {
    track.stop();
  });
  recordStream.getVideoTracks().forEach(function (track) {
    track.stop();
  });
};

export {
  startAudioRecording,
  stopAudioRecording,
  makeRandomName,
  startVideoRecording,
  stopVideoRecording,
};
