import axios from "axios";
import { show_single_script } from "./ApiList";
import { removeUnwantedTextController } from "./ManuallyPageScrollController/removeUnwantedText";

export const nativeWindowDataFetchController = async (
  user_id,
  script_id,
  setLoader
) => {
  const res = await axios.post(show_single_script, {
    user_id: user_id && user_id.slice(9),
    script_id: script_id && script_id,
  });

  const data = res.data.data;
  const msg = res.data.message;
  if (msg === "Single Script Data") {
    setLoader(false);
    const scrAttrText = await removeUnwantedTextController(data.scrAttrText);
    data.hiddenData = scrAttrText;
    return data;
  } else {
    setLoader(false);
  }
};

// setTitle(data.scrTitle);

// setAttributedText(data.scrAttrText);
// promptData = data;
// setPromptTextSize(data.scrPromptTextSize);
// setTextMargin(data.textMargin);
// // setPromptData(data);
// const scrAttrText = removeUnwantedTextController(data.scrAttrText);
// console.log(scrAttrText, "v");

// setLoader(false);
