import { combineReducers } from "redux";

import authReducer from "../reducers/auth.reducer";
import userReducer from "../reducers/user.reducer";
import updateScriptReducer from "../reducers/update.script.reducer";

import showFolderScriptReducer from "./showFolderScript.reducer";
import showScriptStoryboardReducer from "./showStoryBoards.reducer";
import subscriptionStatusReducer from "./subscriptionStatus.reducer";
import { loaderReducer } from "./loader.reducer";
import { settingReducer } from "./setting.reducers";

import {
  selectedScriptReducer,
  showAllScriptReducer,
} from "./showAllScript.reducer";
import {
  enableDisableEditReducer,
  scrollSpeedReducer,
} from "./scrolling.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  updateScript: updateScriptReducer,
  showAllScripts: showAllScriptReducer,
  showSingleFolderScripts: showFolderScriptReducer,
  showSingleScriptStoryboard: showScriptStoryboardReducer,
  subscriptionStatus: subscriptionStatusReducer,
  requestLoader: loaderReducer,
  setting: settingReducer,
  scrollValue: scrollSpeedReducer,
  enableEdit: enableDisableEditReducer,
  selectedScriptData: selectedScriptReducer,
});

export default rootReducer;
