import React, { useState, useEffect } from "react";
import AOS from "aos";
import styled from "styled-components";

import ScrollingFooter from "./ScrollingFooter";
import Seek, { testRange } from "../Range/Seek";
import { useWindowScroll } from "react-use";
import UseRecorder from "../AudioRecord/UseRecorder";
import { saveAs } from "file-saver";
import LoadingOverlay from "react-loading-overlay";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./scroll.css";
import "aos/dist/aos.css";
import $ from "jquery";
import {
  changeThumbnailPosition,
  updatePromptScriptDataController,
  disableEditController,
  contentHeightController,
  fullVideoPlayController,
  stopScrollController,
  startVideoController,
  scrollingFooterController,
  promptScrColorController,
  seekBarFooterController,
} from "../../utils/ScrollController";
import { Redirect } from "react-router";
import { toastOptions } from "../../utils/ToastAlert";
import {
  startManualPageScrollController,
  stopManualPageScrollController,
} from "../../utils/ManuallyPageScrollController/manuallyPageScrollController";
import queryString from "query-string";

import { nativeWindowDataFetchController } from "../../utils/NativeAppDataController";
import { startVideoRecordingController } from "../VideoRecord/Video&AudioRecordingController/StartVideoRecordingController";
import { startAudioRecordingController } from "../VideoRecord/Video&AudioRecordingController/StartAudioRecording";
var Url = require("url-parse");

// ..
AOS.init();
function NativePromptWindow() {
  let promptData = {
    user_id: 969,
    script_id: "9927",
    scrTitle: "test",
    scrText: "description",
    scrAttrText: "description",
    scrEditTextSize: 12,
    scrPromptTextSize: 20,
    scrPromptSpeed: 1,
    textMargin: "left",
    marker: false,
    markerX: 140,
    uuid: "2302-1634888741875",
    scrManualScrolling: "false",
    scrCameraRecordMe: "false",
    scrShowMeFullScreen: "false",
    scrShowMeThumbnail: "false",
    scrVoiceRecordMe: "false",
    scrColor: "#000",
    scrAlignment: "left",
    scrMirror: "",
    scrInvert: "",
    scrFontFamily: "",
  };

  var url = new Url(window.location.href);
  const [loader, setLoader] = useState(false);

  const substringData = url && url.hash.substring(5);
  const query = queryString.parse(substringData && substringData);
  let user_id = query && query.response;
  let script_id = query && query.script_id;

  const [title, setTitle] = useState("");
  const [attrText, setAttributedText] = useState("");
  const [promptTextSize, setPromptTextSize] = useState(
    promptData.scrPromptTextSize
  );
  const [textMargin, setTextMargin] = useState(promptData.textMargin);

  const [attrTxtHidden, setAttrTxtHidden] = useState("");

  const [nativeTitleAction, setNativeTitleAction] = useState(true);

  console.log(user_id, script_id);

  useEffect(async () => {
    setLoader(true);
    const nativeData = await nativeWindowDataFetchController(
      user_id,
      script_id,
      setLoader
    );

    setTitle(nativeData && nativeData.scrTitle);

    setAttributedText(
      nativeData &&
        nativeData.scrAttrText &&
        nativeData.scrAttrText.split("font-size").join("")
    );
    promptData = nativeData && nativeData;
    setPromptTextSize(nativeData && nativeData.scrPromptTextSize);
    setTextMargin(nativeData && nativeData.textMargin);
    // setPromptData(data);
    setAttrTxtHidden(nativeData && nativeData.hiddenData);

    setLoader(false);
  }, []);
  let data = "";
  let error = false;
  if (promptData === null || promptData === undefined) {
    return <Redirect to="/error" />;
  }

  let scrFullScreen;
  let thumbnailCheckeded;
  let videoRecordMe;
  let voiceRecordMe;
  let scrManualScroll;
  let scrollLoop = [];
  var verticalYOffset;

  const [scrColor, setScrColor] = useState(promptData && promptData.scrColor);

  useEffect(() => {
    let scrData = {
      scrColor,
      setScrColor,
    };

    promptScrColorController(scrData);
  }, []);

  const [footerPanalAction, setFooterPanalAction] = useState(false);

  const [scrMirror, setScrMirror] = useState(false);
  const [scrInvert, setScrInvert] = useState(false);
  const [manuallyScrollAction, setManuallyScrollAction] = useState(false);

  const [topToBottomAction, setTopToBottomAction] = useState(false);

  // const attrText = removeUnwantedAttributedTextController(
  //   attrTextTxt && attrTextTxt
  // );
  let [timers, setTimers] = useState([]);

  //audio recording
  let [isRecording, startRecording, stopRecording] = UseRecorder();
  const [recordStartIcon, setRecordIcon] = useState(false);
  const [audioRecordChecked, setAudioRecordChecked] = useState(false);

  //video
  const [recorder, setRecorder] = useState(null);
  const [startVideoRecordIcon, setStartVideoRecordIcon] = useState(true); // vice-versa for audio & video record icon
  const [stream, setStream] = useState(null);

  //video modes
  const [fullMode, setFullMode] = useState(false);
  const [thumbModes, setThumbModes] = useState(false);

  const [playAction, setPlayAction] = useState(false);
  const [stopAudioRecording, setStopAudioRecording] = useState(false);
  const [recordChecked, setRecordChecked] = useState(false);
  const [videoStop, setVideoStop] = useState(false);
  const [startVoiceRecognition, setStartVoiceRecognition] = useState(false);

  const [startRecordIcon, setStartRecordIcon] = useState(false);
  const { y } = useWindowScroll();

  const [thumbnailChecked, setThumbnailChecked] = useState(false);
  const [fullScreenChecked, setFullScreenChecked] = useState(false);

  const [stopCamera, setStopCamera] = useState(null);
  const [txtViewContentHeight, setTxtViewContentHeight] = useState(0);

  let stopScrollData = {
    stopAudioRecording,
    setPlayAction,
    timers,
  };

  var startVideoControllerPassData = {
    setRecordIcon,
    setFullMode,
    setThumbModes,
    setStopCamera,
  };

  useEffect(() => {
    const heightDiv =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    setTxtViewContentHeight(heightDiv);
  }, [y]);

  useEffect(() => {
    contentHeightController();
  }, []);

  // update scripts
  const updatePromptScript = async () => {
    changeThumbnailPosition();

    setLoader(false);
  };

  // view start video:
  const startVideo = () => {
    setRecordIcon(false);
    setFullMode(true);
    setThumbModes(true);
    navigator.getUserMedia(
      {
        video: true,
        audio: true,
        // mimeType: "video/mp4;codecs:h264",
      },

      (stream) => {
        let video = document.getElementsByClassName("app__videoFeed")[0];
        let video1 = document.getElementsByClassName("app__videoFeed2")[0];
        let video2 = document.getElementsByClassName("app__videoFeed3")[0];

        setStopCamera(stream);
        if (video) {
          video.srcObject = stream;
        }
        if (video1) {
          video1.srcObject = stream;
        }
        if (video2) {
          video2.srcObject = stream;
        }
      },
      (err) => {
        toast.error("Media Device is not found", toastOptions);
      }
    );
  };
  //stop video an green light
  const stopVideo = () => {
    if (videoStop === true && fullMode === true) {
      setThumbnailChecked(false);
      setFullScreenChecked(false);
      if (stopCamera === null) {
        return false;
      } else {
        stopCamera.getTracks().forEach((track) => {
          track.stop();
        });
      }
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "#000";
      fullvideo.style.display = "none";

      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "none";
    } else if (videoStop === true && thumbModes === true) {
      setThumbnailChecked(false);
      setFullScreenChecked(false);

      if (stopCamera === null) {
        return false;
      } else {
        stopCamera.getTracks().forEach((track) => {
          track.stop();
        });
      }
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "#000";
      fullvideo.style.display = "none";

      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "none";
    } else {
      if (fullMode === true) {
        setThumbnailChecked(false);
        setFullScreenChecked(false);

        if (stopCamera === null) {
          return false;
        } else {
          stopCamera.getTracks().forEach((track) => {
            track.stop();
          });
        }
        const fullvideo = document.getElementById("full__video");
        fullvideo.style.display = "#000";
        fullvideo.style.display = "none";

        const thumb = document.getElementById("thumbnail__video");
        thumb.style.display = "none";
      } else if (thumbModes === true) {
        setThumbnailChecked(false);
        setFullScreenChecked(false);
        if (stopCamera === null) {
          return false;
        } else {
          stopCamera.getTracks().forEach((track) => {
            track.stop();
          });
        }
        const fullvideo = document.getElementById("full__video");
        fullvideo.style.display = "#000";
        fullvideo.style.display = "none";

        const thumb = document.getElementById("thumbnail__video");
        thumb.style.display = "none";
      }
    }
  };

  const handleChangeFullScreen = async (e = false) => {
    if (!e) {
      scrFullScreen =
        promptData.scrShowMeFullScreen.toLowerCase() === "false" ? false : true;
    } else {
      scrFullScreen = e.target.checked;
    }
    setRecordIcon(false);
    setFullScreenChecked(scrFullScreen);
    let VideoPlayControllerData = {
      scrFullScreen: scrFullScreen,
      recordChecked,
      setRecordChecked,
      setThumbnailChecked,
      videoStop: videoStop,
      thumbModes,
    };

    await fullVideoPlayController(
      VideoPlayControllerData,
      startVideo,

      stopVideo
    );
  };

  //thumbnail video
  const handleChangeThumb = async (e = false) => {
    setRecordIcon(false);
    if (!e) {
      thumbnailCheckeded =
        promptData.scrShowMeThumbnail.toLowerCase() === "false" ? false : true;
    } else {
      thumbnailCheckeded = e.target.checked;
    }
    setThumbnailChecked(thumbnailCheckeded);

    if (recordChecked === true) {
      if (thumbnailCheckeded === true) {
        // startVideo();
        const fullvideo = document.getElementById("full__video");
        fullvideo.style.display = "none";
        fullvideo.style.backgroundColor = "#000";
        const thumb = document.getElementById("thumbnail__video");
        thumb.style.display = "contents";

        // document.getElementById("pause__icon").style.marginLeft = "20%";

        document.getElementById("contentDiv").style.backgroundColor = "#000";
        document.getElementById("contentDiv").style.opacity = "1";
        setFullScreenChecked(false);
      } else {
        if (
          // recordChecked === false &&
          thumbnailCheckeded === false &&
          videoStop === false
        ) {
          const thumb = document.getElementById("thumbnail__video");
          thumb.style.display = "none";
          const fullvideo = document.getElementById("full__video");
          fullvideo.style.display = "inline";
        }
      }
    } else if (thumbnailCheckeded === true) {
      // startVideo();

      startVideoController(startVideoControllerPassData);
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "none";
      fullvideo.style.backgroundColor = "#000";
      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "contents";

      // document.getElementById("pause__icon").style.marginLeft = "20%";

      document.getElementById("contentDiv").style.backgroundColor = "#000";
      document.getElementById("contentDiv").style.opacity = "1";
      setFullScreenChecked(false);
    } else {
      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "none";
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "inline";
      // document.getElementById("pause__icon").style.marginLeft = "-20%";
      stopVideo();
      // setFullScreenChecked(true);
    }
  };

  useEffect(() => {
    scrollingFooterController();
  }, []);

  //timer

  const [time, setTime] = useState({ ms: 0, s: 0, m: 0, h: 0 });
  const [inerv, setInerv] = useState(null);

  var updateMs = time.ms;
  var updateS = time.s;
  var updateM = time.m;
  var updateH = time.h;
  const start = () => {
    run();
    setInerv(setInterval(run, 10));
  };

  const reset = () => {
    clearInterval(inerv);
    setTime({ ms: 0, s: 0, m: 0, h: 0 });
  };

  const run = () => {
    if (updateM === 60) {
      updateH++;
      updateM = 0;
    }
    if (updateS === 60) {
      updateM++;
      updateS = 0;
    }
    if (updateMs === 100) {
      updateS++;
      updateMs = 0;
    }
    updateMs++;
    return setTime({ ms: updateMs, s: updateS, m: updateM, h: updateH });
  };

  //stop Audio recording
  const stopAudioRecordingFun = async () => {
    await stopRecording();
    stopScrollController(stopScrollData);
    setStartRecordIcon(false);
    setStopAudioRecording(false);
    // setMicActive(true);
    // setFullScreenChecked(false);
    setPlayAction(false);
    setRecordIcon(false);
  };

  //stop video recording

  const stopVideoRecording = async () => {
    if (videoStop === true) {
      if (recorder === null) {
        setThumbnailChecked(false);
        setFullScreenChecked(false);
        setVideoStop(false);

        stopVideo();
        setStartRecordIcon(false);
        stopScrollController(stopScrollData);
        setStartVideoRecordIcon(true);
        setRecordChecked(false);
        return false;
      } else {
        console.log(recorder, "asdf");

        await recorder.stopRecording();

        setThumbnailChecked(false);
        setFullScreenChecked(false);
        setVideoStop(false);

        stopVideo();
        setStartRecordIcon(false);
        stopScrollController(stopScrollData);
        setStartVideoRecordIcon(true);
        setRecordChecked(false);
        // setStopVideoRecordingIcon(false);

        const fullvideo = document.getElementById("full__video");
        fullvideo.style.display = "none";
        const thumb = document.getElementById("thumbnail__video");
        thumb.style.display = "none";
        const blob = await recorder.getBlob();

        console.log(blob, "blob");
        document.getElementById("contentDiv").style.backgroundColor = "#000";
        document.getElementById("contentDiv").style.opacity = "1";
        setStream(null);
        setRecorder(null);

        stream.stop();

        if (blob) {
          let date = new Date();
          let sd = date.toISOString().substring(0, 10);
          let hh = date.getHours();
          let mm = date.getMinutes();
          let s = date.getSeconds();

          let fullDateTime = sd + " " + hh + "_" + mm + "_" + s;

          if (navigator.appVersion.indexOf("Win") != -1) {
            saveAs(blob, `Video - ${title}-${fullDateTime}.mp4`);
            toast("Video recording saved successfully!");
          } else {
            saveAs(blob, `Video - ${title}-${fullDateTime}`);
            toast("Video recording saved successfully!");
          }
        }
      }
    }
  };
  // video toggle

  const videoToggle = async (e = false) => {
    if (!e) {
      videoRecordMe =
        promptData.scrCameraRecordMe.toLowerCase() === "true" ? true : false;
    } else {
      videoRecordMe = e.target.checked;
    }
    if (videoRecordMe === true) {
      setRecordChecked(true);
      setStartVoiceRecognition(false);
      setRecordIcon(false); //voiceRecordMe record icon
      setAudioRecordChecked(false); //voiceRecordMe toggle icon
      setStartVideoRecordIcon(false); // we set default video record icon is true for audio record issue
    } else {
      setRecordChecked(false);
      setStartVideoRecordIcon(true); //hide video record icon
      stopVideoRecording();
      stopVideo();
      setStartVoiceRecognition(false);
      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "none";
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "none";
      document.getElementById("scroll__content").style.backgroundColor = "#000";
      document.getElementById("contentDiv").style.backgroundColor = "#000";
      document.getElementById("contentDiv").style.opacity = "1";
    }
    // console.log(promptData.scrCameraRecordMe, "db");
  };

  // voice recordMe Function

  const micHandleChange = async (e = false) => {
    if (!e) {
      voiceRecordMe =
        promptData.scrVoiceRecordMe.toLowerCase() === "false" ? false : true;
    } else {
      voiceRecordMe = e.target.checked;
    }
    setRecordIcon(false);
    setAudioRecordChecked(voiceRecordMe);
    if (voiceRecordMe === true) {
      setRecordChecked(false);
      setStartVideoRecordIcon(true); //hide video record icon
      if (videoStop === true) {
        stopVideoRecording();
      } else if (fullMode === true) {
        stopVideo();
        setRecordIcon(true);

        const thumb = document.getElementById("thumbnail__video");
        thumb.style.display = "none";
        const fullvideo = document.getElementById("full__video");
        fullvideo.style.display = "none";
        document.getElementById("scroll__content").style.backgroundColor =
          "#000";
        document.getElementById("contentDiv").style.backgroundColor = "#000";
        document.getElementById("contentDiv").style.opacity = "1";
        // setThumbnailChecked(false);
      } else {
        setRecordIcon(true);
        await startRecording();
        // document.getElementById("hideToggleAudio").style.display =
        //   "inline-block";
        // document.getElementById("showToggleAudio").style.display = "none";
      }
    } else {
      setRecordIcon(false);
      stopAudioRecordingFun();
    }
  };

  //Mirror Script Function
  const mirror = async (e = false) => {
    let checked;
    if (!e) {
      checked = promptData.scrMirror === "1" ? true : false;
    } else {
      checked = e.target.checked;
    }

    if (checked === true) {
      const mirrorData = document.getElementById("contentDiv");
      mirrorData.style.transform = "scale(-1, 1)";
      setScrMirror(true);
    } else if (scrInvert === true && checked === true) {
      const mirrorData = document.getElementById("contentDiv");
      mirrorData.style.transform = "scale(-1, -1)";
      setScrMirror(true);

      // console.log(scrMirror);
    } else {
      const mirrorData = document.getElementById("contentDiv");
      mirrorData.style.transform = "scale(1, 1)";
      setScrMirror(false);
    }
  };

  // Invert Script Function
  const invert = async (e = false) => {
    let check;
    if (!e) {
      check = promptData.scrInvert === "1" ? true : false;
    } else {
      check = e.target.checked;
    }
    setScrInvert(check);
    if (check === true) {
      const mirrorData = document.getElementById("contentDiv");
      mirrorData.style.transform = "scale(1, -1)";
      let scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      window.scrollTo(0, scrollHeight - 15);
      // window.scrollTo(0, document.body.scrollHeight);
      setTopToBottomAction(true);
      //
    } else if (check === true && scrMirror === true) {
      const mirrorData = document.getElementById("contentDiv");
      mirrorData.style.transform = "scale(-1, -1)";
      setTopToBottomAction(true);
      //
    } else {
      const mirrorData = document.getElementById("contentDiv");
      mirrorData.style.transform = "scale(1, 1)";
      window.scroll({ top: 0, left: 0 });
      setTopToBottomAction(false);
      //
    }
  };

  //voice recogination setting
  const micRecognitionHandleChange = async (e = false) => {
    if (!e) {
      scrManualScroll =
        promptData.scrManualScrolling.toLowerCase() === "false" ? true : true;
    } else {
      scrManualScroll = e.target.checked;
    }

    setStartVoiceRecognition(scrManualScroll);
    if (scrManualScroll) {
      recordChecked && recordChecked === true // video recoding
        ? startVideoRecordingController(
            setStream,
            setRecorder,
            setVideoStop,
            setThumbnailChecked,
            setStartRecordIcon
          )
        : console.log("off");

      audioRecordChecked && audioRecordChecked === true // audio recording
        ? startAudioRecordingController()
        : console.log("off");
      setStartVideoRecordIcon(true);
    } else {
      setStartVideoRecordIcon(true);
    }
  };

  //autoload  setting functions

  useEffect(() => {
    handleChangeFullScreen();
    handleChangeThumb();
    videoToggle();
    micHandleChange();
    micRecognitionHandleChange();
    mirror();
    invert();
  }, []);

  // voice recogination

  const [val, setVal] = useState(3);

  useEffect(() => {
    $("#movable").click(function () {
      // $("#scroll__para").css("width", "calc(100% - 150px)");
      if ($(this).attr("trigger") === "0") {
        var x = window.matchMedia("(max-width: 1024px)");

        if (x.matches) {
          $(this).animate({ left: 80 + "%" }, 1400);
          setVal("1");
        } else {
          $(this).animate({ left: 88 + "%" }, 1400);
          setVal("1");
        }
      } else if ($(this).attr("trigger") === "1") {
        $(this).animate({ top: 75 + "vh" }, 700);

        setVal("2");
      } else if ($(this).attr("trigger") === "2") {
        $(this).animate({ top: 75 + "vh", left: "0px" }, 1400);

        setVal("3");
      } else if ($(this).attr("trigger") === "3") {
        $(this).animate({ top: "0px", left: "0px" }, 700);

        setVal("0");
      }
    });
  }, []);
  useEffect(() => {
    const move = document.getElementById("movable");
    move.style.right = "10px";
    move.style.bottom = "35vh";
    // document.getElementById("voiceRecoMedia").style.display = "none";
  }, []);

  // bottom to top page scroll
  const startBottomToTopScroll = () => {
    setManuallyScrollAction(true);
    document.getElementById("scroll__header").style.display = "none";

    var textViewPromptHeight = localStorage.getItem("contentHeight") * 1000;

    seekBarFooterController();
    setPlayAction(true);
    localStorage.setItem("speedRange", 1);
    for (let i = 0; i < textViewPromptHeight; i++) {
      if (i === textViewPromptHeight) {
        scrollLoop.push(false);
      } else {
        scrollLoop.push(true);
      }
    }
    // call function iside fun
    scrollFun(function repeat(scrollLoop) {
      verticalYOffset = window.pageYOffset;

      var currentScrollPoint = Math.floor(verticalYOffset);
      if (
        currentScrollPoint === txtViewContentHeight ||
        currentScrollPoint === txtViewContentHeight + 1
      ) {
        while (timers.length > 0) {
          clearTimeout(timers.pop());
        }
      } else if (currentScrollPoint === 0 || currentScrollPoint === 15) {
        while (timers.length > 0) {
          clearTimeout(timers.pop());
        }
      } else {
        if (scrollLoop.winner) {
          scrollFun(repeat);
          var scrSpeedRatio = localStorage.getItem("speedRange");

          // window.scrollBy(0, -scrSpeedRatio)
          let x = testRange.toString();
          window.scrollBy(0, -x);

          const scrScroll = document.getElementById("contentDiv");
          scrScroll.style.scrollBehavior = "smooth";
        }
      }
    });
    // call back function
    function scrollFun(callback) {
      var scrSpeedRatio = parseInt(localStorage.getItem("speedRange"));
      const data = setTimeout(function () {
        callback({ winner: scrollLoop.shift() });
      }, ((scrSpeedRatio / 4) * 100) | 0);
      setTimers((prevArray) => [...prevArray, data]);
    }

    start(); //timer
  };

  // templates starts

  return (
    <LoadingOverlay active={loader} spinner>
      <div
        id="scroll__para"
        className="text-white"
        // style={{ backgroundColor: "#000" }}
      >
        <div id="thumbnail__video">
          <div className="thumbnail_video">
            <div id="movable" trigger={val}>
              <video
                height="150px"
                width="150px"
                muted
                autoPlay
                className="app__videoFeed3"
              ></video>
            </div>
          </div>
        </div>
        {startRecordIcon ? (
          <div className="blobs-container">
            <div className="blob red">
              <span className="record-text">Rec.</span>
            </div>
          </div>
        ) : null}

        <BackgroundVideo id="full__video">
          <div className="premeeting-screen">
            <div id="preview">
              <video
                id="video"
                muted
                autoPlay
                className="app__videoFeed "
              ></video>
            </div>
          </div>
        </BackgroundVideo>

        <ScriptScrollBtn>
          <div id="voiceRecoMedia">
            {error ? (
              <div id="voiceRecoMedia1">
                {/* <Alert severity="error" data-aos="fade-left">
                  <p className="text-white">
                    Media Device is not found!{" "}
                    <span
                      className="mediaDevicesErrorIcon"
                      onClick={() => hideVoiceErrorController()}
                    >
                      <i class="fa fa-times"></i>
                    </span>{" "}
                  </p>
                </Alert> */}
              </div>
            ) : null}
          </div>
          <div className="" id="scrolling__footer">
            <ScrollingFooter
              setFooterPanalAction={setFooterPanalAction}
              setManuallyScrollAction={setManuallyScrollAction}
              startBottomToTopScroll={startBottomToTopScroll}
              updatePromptScript={updatePromptScript}
              startVoiceRecognition={startVoiceRecognition}
              setStartVoiceRecognition={setStartVoiceRecognition}
              setStartRecordIcon={setStartRecordIcon}
              txtViewContentHeight={txtViewContentHeight}
              stopVideo={stopVideo}
              setFullScreenChecked={setFullScreenChecked}
              setThumbnailChecked={setThumbnailChecked}
              stopCamera={stopCamera}
              handleChangeThumb={handleChangeThumb}
              handleChangeFullScreen={handleChangeFullScreen}
              fullScreenChecked={fullScreenChecked}
              thumbnailChecked={thumbnailChecked}
              setPlayAction={setPlayAction}
              playAction={playAction}
              setStopAudioRecording={setStopAudioRecording}
              stopAudioRecording={stopAudioRecording}
              start={start}
              recordChecked={recordChecked}
              setRecordChecked={setRecordChecked}
              setVideoStop={setVideoStop}
              videoStop={videoStop}
              setRecordIcon={setRecordIcon}
              stopAudioRecordingFun={stopAudioRecordingFun}
              recordStartIcon={recordStartIcon}
              isRecording={isRecording}
              startRecording={startRecording}
              // setStopVideoRecordingIcon={setStopVideoRecordingIcon}
              setStream={setStream}
              setRecorder={setRecorder}
              startVideoRecordIcon={startVideoRecordIcon}
              setStartVideoRecordIcon={setStartVideoRecordIcon}
              stopVideoRecording={stopVideoRecording}
              setFullMode={setFullMode}
              fullMode={fullMode}
              audioRecordChecked={audioRecordChecked}
              setAudioRecordChecked={setAudioRecordChecked}
              setTimers={setTimers}
              timers={timers}
              scrInvert={scrInvert}
              setScrInvert={setScrInvert}
              scrMirror={scrMirror}
              setScrMirror={setScrMirror}
              topToBottomAction={topToBottomAction}
              setTopToBottomAction={setTopToBottomAction}
              textMargin={textMargin}
              setTextMargin={setTextMargin}
              setPromptTextSize={setPromptTextSize}
              promptTextSize={promptTextSize}
              videoToggle={videoToggle}
              micHandleChange={micHandleChange}
              //voice recogination passing props

              mirror={mirror}
              invert={invert}
              micRecognitionHandleChange={micRecognitionHandleChange}
              startVideo={startVideo}
            />
          </div>

          <div className="" id="seek__footer">
            <Seek
              // stopScroll={stopScroll}
              title={title}
              nativeTitleAction={nativeTitleAction}
              stopScrollData={stopScrollData}
              stopTimer={reset}
              time={time}
              isRecording={isRecording}
              stopAudioRecordingFun={stopAudioRecordingFun}
              stopVideoRecording={stopVideoRecording}
              videoStop={videoStop}
              setVideoStop={setVideoStop}
              audioRecordChecked={audioRecordChecked}
              setAudioRecordChecked={setAudioRecordChecked}
              setStartVoiceRecognition={setStartVoiceRecognition}
              startVoiceRecognition={startVoiceRecognition}
            />
          </div>
        </ScriptScrollBtn>
        {/* <div id="voiceRecoMedia">
          {error ? (
            <div id="voiceRecoMedia1">
              <Alert severity="error" data-aos="fade-left">
                <p className="text-white">
                  Media Device is not found!{" "}
                  <span
                    className="mediaDevicesErrorIcon"
                    onClick={() => hideVoiceErrorController()}
                  >
                    <i class="fa fa-times"></i>
                  </span>{" "}
                </p>
              </Alert>
            </div>
          ) : null}
        </div> */}
        <div className="script-scroll-heading bg-dark" id="scroll__header">
          <h5>{title}</h5>
        </div>
        <div
          onClick={() =>
            footerPanalAction
              ? manuallyScrollAction
                ? stopManualPageScrollController(
                    setTopToBottomAction,
                    timers,
                    setPlayAction,
                    setManuallyScrollAction
                  )
                : topToBottomAction
                ? startBottomToTopScroll()
                : startManualPageScrollController(
                    setTopToBottomAction,
                    scrollLoop,
                    verticalYOffset,
                    timers,
                    testRange,
                    setTimers,
                    setPlayAction,
                    txtViewContentHeight,
                    setManuallyScrollAction
                  )
              : null
          }
          className="p-5 main-prompt-page-outer"
          id="contentDiv"
          style={{
            minHeight: "100vh",
            maxWidth: "100vw",
            transform: scrMirror === true ? "scale(-1, 1)" : "scale(1, 1)",

            fontSize: promptTextSize + "px",
            overflowX: "auto",
          }}
        >
          <p
            style={{
              padding: textMargin + "%",
              // color: scrColor,
            }}
            id="scroll__content"
            onFocus={() => disableEditController()}
            dangerouslySetInnerHTML={{
              __html:
                attrText &&
                attrText
                  .replace(/$|#000000|\^/gi, "#fff")
                  .replace(/$|#fff|\^/gi, "#000")
                  .replace(/$|#ffffff|\^/gi, "#000")
                  .replace(/$|255, 255, 255|\^/gi, "#000"),
            }}
          ></p>
        </div>
        <div
          className="p-5 main-prompt-page-hidden"
          id="contentDiv"
          style={{
            minHeight: "100vh",
            maxWidth: "100vw",
            transform: scrMirror === true ? "scale(-1, 1)" : "scale(1, 1)",

            fontSize: promptTextSize + "px",
            overflowX: "auto",
          }}
        >
          <p
            style={{
              padding: textMargin + "%",
              // color: scrColor,
            }}
            id="scroll__content_hidden"
            onFocus={() => disableEditController()}
            dangerouslySetInnerHTML={{
              __html:
                attrTxtHidden && attrTxtHidden.split("#000000").join("#fff"),
            }}
          ></p>
        </div>
      </div>
      <ToastContainer />
    </LoadingOverlay>
  );
}

const ScriptScrollBtn = styled.div`
  position: fixed;
  z-index: 10000;
  bottom: 0px;
  width: 100%;
`;

const BackgroundVideo = styled.div`
  position: fixed;
  z-index: -1;
  height: 100%;
  width: 100vw;
  background-color: #333;
`;

export default NativePromptWindow;
