import { showFoldersConstants } from "./constants";

import axios from "../../helpers/axios";

let user_id = localStorage.getItem("userId");
export const showFolder = (folders) => {
  // console.log(folders, user_id);
  return async (dispatch) => {
    dispatch({
      type: showFoldersConstants.SHOW_FOLDERS_REQUEST,
      payload: {
        ...folders,
      },
    });
    const res = axios.post(`${process.env.REACT_APP_API_URL}/show_folders`, {
      user_id: user_id,
    });
    //   .then(function (response) {
    //     console.log(response);
    //     const arr = response.data.data;

    //     const folder_data =
    //       arr.length === 0 ? null : arr.map((element) => element.name);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // console.log("folders", res);
    dispatch({
      type: showFoldersConstants.SHOW_FOLDERS_SUCCESS,
      payload: {
        res,
      },
    });
    // if(res.status === 200){

    // }
  };
};
