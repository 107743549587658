import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userlogout } from "../../services/actions";
import { GoogleLogout } from "react-google-login";

export default function LogoutModal() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const signOut = () => {
    dispatch(userlogout());
    window.opener.location.href = window.opener.location.href;

    if (window.opener.progressWindow) {
      window.opener.progressWindow.close();
    }
    window.close();
    setOpen(false);
  };

  return (
    <div>
      <h5 className="pro-heading" onClick={handleClickOpen}>
        Logout
      </h5>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to logout?"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Link onClick={handleClose} className="cancel_logout_btn">
            Cancel
          </Link>
          <GoogleLogout
            clientId={process.env.REACT_APP_API_TEST_GOOGLE_CLIENT_ID}
            buttonText="Sign Out"
            onLogoutSuccess={signOut}
            className="d-none"
          ></GoogleLogout>
          <Link to="/login" onClick={() => signOut()} className="logout_btn">
            Ok
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
}
