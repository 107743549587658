import { folderScriptConstants, loaderConstants } from "./constants";

export const loaderAction = (loader) => async (dispatch) => {
  dispatch({
    type: loader ? loaderConstants.LOADER_START : loaderConstants.LOADER_STOP,
  });
};

export const shortAllFolderScripts = (data) => {
  return async (dispatch) => {
    dispatch({
      type: folderScriptConstants.FOLDER_RELOAD_SUCCESS,
      payload: { folderScriptData: data.data, msg: "", loading: false },
    });
  };
};
