import { toast } from "react-toastify";
import axiosInstance from "../../helpers/axios";
import {
  showAllScriptConstants,
  showStoryBoardsConstants,
  updateScriptConstants,
} from "./constants";

export const showAllScripts = () => {
  return async (dispatch) => {
    const user_id = localStorage.getItem("userId");
    await axiosInstance
      .post(`${process.env.REACT_APP_API_URL}/show_scripts`, {
        user_id: user_id,
      })
      .then((response) => {
        // alert("test");
        if (response.data.code == 200) {
          const arr = response.data.data;

          localStorage.setItem("totalScript", JSON.stringify(arr[0]));

          dispatch({
            type: showAllScriptConstants.USER_ALL_SCRIPT_SUCCESS,
            payload: { temp: arr[0], loader: false },
          });
        } else {
          let temp = [];
          localStorage.setItem("totalScript", JSON.stringify(temp));
          if (temp.length === 0) {
            dispatch({
              type: showStoryBoardsConstants.SHOW_STORYBOARD_SUCCESS,
              payload: { storyboards: [] },
            });
          }
        }
      })
      .catch((error) => {
        let res = error && error.response;
        if (!res) {
          alert(error.message);
          return false;
        }
        let { response } = error;

        console.log(response, "response: ");

        const { message, code } = response.data;

        if (code == 401) {
          toast.warn(message);
          setTimeout(() => {
            window.location.href = "/";
            localStorage.clear();
            //console.clear();
          }, 1000);
        } else {
          toast.error(message);
        }
      });
  };
};

const editScriptAction = (boolean) => async (dispatch) => {
  dispatch({
    type: updateScriptConstants.ENABLE_EDIT_SCRIPT,
    payload: boolean,
  });
};

export { editScriptAction };
