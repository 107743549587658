const colorArray = [
  "#000000",
  "#FF1A66",
  "#E6331A",
  "#FF6633",

  "#FF33FF",
  "#B33300",
  "#3366E6",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",

  "#6680B3",
  "#66991A",

  "#33991A",

  "#B3B31A",
  "#00E680",
  "#4D8066",

  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",

  "#6666FF",
  "white",
];

export { colorArray };
