import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loaderAction, userlogout } from "../../services/actions";
import axios from "axios";
import { delete_user } from "../../utils/ApiList";
import { toast } from "react-toastify";

export default function DeleteAccount() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const deleteAccount = async () => {
    dispatch(loaderAction(true));

    let userId = localStorage.getItem("userId");
    const { data } = await axios.post(delete_user, {
      user_id: userId,
    });
    if (data.success == 1) {
      toast.success(data.message);
    }

    window.opener.location.href = window.opener.location.href;

    if (window.opener.progressWindow) {
      window.opener.progressWindow.close();
    }
    window.close();
    dispatch(userlogout());

    dispatch(loaderAction(false));
    setOpen(false);
  };

  return (
    <div className="delete-account">
      <h6 className="pro-heading" onClick={handleClickOpen}>
        Delete Account
      </h6>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Permanently Delete Account?"}
        </DialogTitle>
        <DialogContent>
          I understand that by deleting this account, I am permanently deleting
          all content associated with it. And that this account and its content
          cannot be recovered and will no longer be accessible on any device.
        </DialogContent>
        <DialogActions>
          <Link onClick={() => deleteAccount()} className="logout_btn">
            I understand. Delete my Account
          </Link>
          <Link onClick={handleClose} className="cancel_logout_btn">
            Cancel
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
}
