import React from "react";

import "video-react/dist/video-react.css";

function VideoRecording(props) {
  const { videoToggle, recordChecked } = props;

  return (
    <div>
      <div className="row">
        <div className="text-option">
          <p>Video</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 p-0">
          <div className="switch-cl inc-font">
            <span className="Mirror text-left">Record me</span>
            <span className="toggle-btn text-right">
              <input
                type="checkbox"
                id="switch4"
                onChange={videoToggle}
                checked={recordChecked}
              />
              <label className="stch" htmlFor="switch4"></label>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoRecording;
