import {
  showAllScriptConstants,
  updateScriptConstants,
} from "../actions/constants";
const initialState = {
  temp: null,
  loader: null,
};

const scriptState = {
  scriptData: null,
};

export const showAllScriptReducer = (state = initialState, action) => {
  // console.log(action);
  // console.log()
  switch (action.type) {
    case showAllScriptConstants.USER_All_SCRIPT_REQUEST:
      state = {
        ...state,
        loader: true,
      };
      break;
    case showAllScriptConstants.USER_ALL_SCRIPT_SUCCESS:
      state = {
        ...state,
        temp: action.payload.temp,
        userId: action.payload.userId,
        authenticate: true,
        authenticating: false,
        loader: false,
      };
      break;

    case showAllScriptConstants.USER_ALL_SCRIPT_FAIL:
      state = {
        ...initialState,
        loader: false,
      };
      break;

    default:
  }

  return state;
};

export const selectedScriptReducer = (state = scriptState, action) => {
  switch (action.type) {
    case updateScriptConstants.SELECTED_SCRIPT:
      return {
        ...state,
        scriptData: action.payload.script,
      };

    default:
      return scriptState;
  }
};
