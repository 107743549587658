import React, { useState, useEffect } from "react";
import $ from "jquery";
import { useWindowScroll } from "react-use";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  addCustomScriptSpaceController,
  removeAddRandomIdController,
  // speechToScrollController,
  startSpeechController,
} from "../../../utils/VoiceController";

import MicIcon from "./mic.png";
import { startVideoRecordingController } from "../../VideoRecord/Video&AudioRecordingController/StartVideoRecordingController";
import { startAudioRecordingController } from "../../VideoRecord/Video&AudioRecordingController/StartAudioRecording";

export default function VoiceRecognition({
  isRecording,
  recordChecked,

  setStream,
  setRecorder,
  setVideoStop,
  setThumbnailChecked,
  setStartRecordIcon,
  startVideo,
  audioRecordChecked,
  startTimer,
}) {
  // const [searchText, setSearchText] = useState(false);
  const [temp, setTemp] = useState(0);

  const { transcript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const appendText = async (transcript) => {
    if (
      transcript !== undefined &&
      transcript.toLowerCase().trim().length > 0
      // transcript !== searchText
    ) {
      // setSearchText(transcript);

      if (window.find(transcript) === false) {
      }
      const testText = await transcript.trim().split(" ").splice(-3);

      const normalText = testText.toString();
      console.log(normalText);
      const plainNormalText = normalText.split(",").join(" ");
      const plainText = plainNormalText.split("'s").join("");

      getOffsetToScroll(plainText);
    }
  };

  // speech to scroll page
  let testId;
  // replace the match words into the random Id's
  const getOffsetToScroll = (matchText) => {
    const inputText = matchText.toLowerCase();

    const data = Date.now();
    var randomId = `imhere${data}`;

    $("#scroll__content_hidden").html(
      $("#scroll__content_hidden")
        .html()
        .replace(inputText, `<a id=${randomId}></a>` + inputText)
    );
    scriptScrollToTop(randomId, inputText);
  };
  //when script is matched then script scroll
  const scriptScrollToTop = (randomId, matchText) => {
    var offset = document.getElementById(randomId);
    var offsetTop = 0;
    if (offset === null) {
      // return false;
    } else {
      offsetTop = offset.offsetTop; //getting offsetTop of the current random  id
      if (temp > offsetTop) {
        // console.log("less", temp);
        removeAddRandomIdController(randomId);
        $("html,body").animate({ scrollTop: temp }, "slow");
        // return false;
      } else {
        offsetTop = offset.offsetTop;

        var offset_top = offsetTop;

        setTemp(offset_top);
        $("html,body").animate({ scrollTop: offset_top }, "slow");
        testId = randomId;
        // whenever script should be scroll then add a space between the matched text
        addCustomScriptSpaceController(randomId, matchText);
      }
    }
  };

  useEffect(() => {
    appendText(transcript.toString());
  }, [transcript.toString()]);

  const { y } = useWindowScroll();
  useEffect(() => {
    let tempY = 0;
    if (y > 0) {
      tempY = y;
    } else {
      removeAddRandomIdController(testId);
    }

    // console.log(y, tempY);
  }, [y]);

  const startSpeechToPageScroll = async () => {
    startTimer();
    if (recordChecked && recordChecked === true) {
      // video recoding
      startVideoRecordingController(
        setStream,
        setRecorder,
        setVideoStop,
        setThumbnailChecked,
        setStartRecordIcon,
        startVideo
      );
    }

    if (audioRecordChecked && audioRecordChecked === true) {
      // audio recoding

      startAudioRecordingController();
    }

    startSpeechController(SpeechRecognition);
  };

  return (
    <>
      <label htmlFor="pause" className="edit-tab scroll-tab-page record-icon ">
        {!isRecording ? (
          <div className="record-icon">
            {/* startRecording.png */}
            <img
              src={
                recordChecked && // record checked is video record icon
                  recordChecked === true
                  ? "assets/images/startRecording.png"
                  : audioRecordChecked && audioRecordChecked === true
                    ? "assets/images/mic.png"
                    : "assets/images/play.svg"
              }
              id="start__recording"
              alt="start video recording1234"
              // onClick={() => SpeechRecognition.startListening({ continuous: true })}
              onClick={() => startSpeechToPageScroll()}
            />
          </div>
        ) : (
          // <i onClick={stopSpeech} className="fa fa-play-circle"></i>
          <div className="record-icon">
            <img
              src="assets/images/mic.png"
              id="start__recording"
              alt="start video recording"
              onClick={() => SpeechRecognition.stopListening()}
            />
          </div>
        )}
      </label>
    </>
  );
}
