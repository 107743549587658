import { testRange } from "../../components/Range/Seek";
import { seekBarFooterController } from "../ScrollController";

export const stopManualPageScrollController = (
  setTopToBottomAction,
  timers,
  setPlayAction,
  setManuallyScrollAction,
  stopTimer
) => {
  // setTopToBottomAction(false)
  setManuallyScrollAction(false);
  localStorage.setItem("speedRange", 0);
  while (timers.length > 0) {
    clearTimeout(timers.pop());
  }
  setPlayAction(false);
  document.getElementById("scroll__header").style.display = "inline";
  stopTimer && stopTimer();
};

export const startManualPageScrollController = (
  setTopToBottomAction,
  scrollLoop,
  verticalYOffset,
  timers,
  testRange1,
  setTimers,
  setPlayAction,
  txtViewContentHeight,
  setManuallyScrollAction,
  start
) => {
  localStorage.setItem("playPause", false);
  document.getElementById("scroll__header").style.display = "none";
  setTopToBottomAction(false);
  setManuallyScrollAction(true);
  start && start();
  localStorage.setItem("speed", 100);

  var textViewPromptHeight = localStorage.getItem("contentHeight") * 1000;
  // props.seekBarFooter();
  seekBarFooterController();
  setPlayAction(true);
  localStorage.setItem("speedRange", 1);
  for (let i = 0; i < textViewPromptHeight; i++) {
    if (i === textViewPromptHeight) {
      scrollLoop.push(false);
    } else {
      scrollLoop.push(true);
    }
  }
  // call function iside function
  scrollFun(function repeat(scrollLoop) {
    console.log("testing 123");

    verticalYOffset = window.pageYOffset;
    var currentScrollPoint = Math.floor(verticalYOffset);
    if (
      currentScrollPoint === txtViewContentHeight ||
      currentScrollPoint === txtViewContentHeight + 1
    ) {
      while (timers.length > 0) {
        clearTimeout(timers.pop());
      }
    } else {
      if (scrollLoop.winner) {
        scrollFun(repeat);
        let x = testRange.toString();
        if (x == 1) {
          x = 0.001;
        } else if (x == 3) {
          x = 2.99;
        } else {
          x = testRange.toString();
        }

        let Boolean = localStorage.getItem("playPause");

        console.log(Boolean, "Booleane23323q34234", typeof Boolean);

        if (Boolean === "true") {
          console.log(Boolean, "Booleane23323");
          while (timers.length > 0) {
            clearTimeout(timers.pop());
          }
          return;
        }

        window.scrollBy(0, x + 1);
        const scrScroll = document.getElementById("scroll__para");
        scrScroll.style.scrollBehavior = "smooth";
        const scrScroll1 = document.getElementById("scroll__content");
        scrScroll1.style.scrollBehavior = "smooth";
      }
    }
  });
  // call back function
  function scrollFun(callback) {
    var scrSpeedRatio = parseInt(localStorage.getItem("speedRange"));

    const data = setTimeout(function () {
      callback({ winner: scrollLoop.shift() });
    }, ((scrSpeedRatio / 4) * 100) | 0);
    setTimers((prevArray) => [...prevArray, data]);
  }
};
