import React, { useState } from "react";

import FilesBox from "../../middlaware/FilesBox";
import "./DropDownMenu.css";

function ImportDropDownMenuComponent({
  googleDriveSendData,
  showAllScript,
  setToggleSelect,
  setLoader,
  setEmailAndPrintAction,
  setBoardAction,
}) {
  const [fileOpen, setFileOpen] = useState(false);

  const handleClick = () => {
    setBoardAction(true);
    // setEmailAndPrintAction(true);
  };

  return (
    <>
      <FilesBox
        googleDriveSendData={googleDriveSendData}
        showAllScript={showAllScript}
        setToggleSelect={setToggleSelect}
        setLoader={setLoader}
        open={fileOpen}
        setOpen={setFileOpen}
        setEmailAndPrintAction={setEmailAndPrintAction}
        handleClick={handleClick}
      />
    </>
  );
}

export default ImportDropDownMenuComponent;
