import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Login from "./pages/Login";
import Dashboard from "./pages/dashboard";
import Forgot from "./pages/forgot";
import Profile from "./pages/profile";
import PrivateRoute from "./HOC/PrivateRoute";
import PromptPageComponent from "./components/Scrolling/PromptPageComponent";
import Error from "./pages/Error";
import NativeAppGoogleAuth from "./GoogleAuth/NativeAppGoogleAuth";
import DummySpeech from "./components/DummySpeech";
import NativePromptWindow from "./components/Scrolling/NativePromptWindow";
import SubscriptionComponent from "./components/SubscriptionComponent/SubscriptionComponent";
import StripePayment from "./components/StripePayment/StripePayment";
import Signup from "./pages/Signup";
import { ReCaptcha } from "./components/ReCaptcha/ReCaptcha";
import AppTemplate from "./components/AppTemplate/AppTemplate";
import RestorePayment from "./components/SubscriptionComponent/RestorePayment";
import { settingAction } from "./services/actions/setting.actions";
import RedirectAppStipePayment from "./components/StripePayment/RedirectAppStipePayment";
import MainRedirectStripePage from "./components/StripePayment/MainRedirectStripePage";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(settingAction());
  }, []);

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/" component={Dashboard} />
        <Route exact path="/login" component={Login} />

        <Route exact path="/signup" component={Signup} />
        <Route path="/profile" component={Profile} />
        <Route path="/forgot" component={Forgot} />

        <Route path="/development" component={Dashboard} />
        <Route path="/prompt" component={PromptPageComponent} />
        <Route
          exact
          path="/native/prompt/page"
          component={NativePromptWindow}
        />
        <Route
          exact
          path="/v1/native/permission"
          component={NativeAppGoogleAuth}
        />
        <Route exact path="/app_badges" component={AppTemplate} />
        <Route exact path="/dummy/speech" component={DummySpeech} />
        <Route exact path="/subscription" component={SubscriptionComponent} />
        <Route exact path="/subscription/checkout" component={StripePayment} />
        <Route
          exact
          path="/app/subscription/checkout"
          component={RedirectAppStipePayment}
        />
        <Route
          exact
          path="/app/subscription/checkout/payment"
          component={MainRedirectStripePage}
        />

        <Route exact path="/recaptcha" component={ReCaptcha} />
        <Route exact path="/restorePayment" component={RestorePayment} />
        <Route component={Error} />
      </Switch>
    </Router>
  );
}

export default App;
