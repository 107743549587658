import { toast } from "react-toastify";
import { covertHtmlToPlainText } from "./convertHtmlToPlainText";
import { toastOptions } from "./ToastAlert";

export const openEmailController = async () => {
  // const title = localStorage.getItem("title");

  download();
  // setTimeout(() => {
  //   const subject = title;
  //   const body = `Your script have been downloaded Please add the Attachment.`;
  //   let url = `mailto:?subject=${subject}&body=${body}`;
  //   const a = document.createElement("a");
  //   document.body.appendChild(a);
  //   a.href = url;
  //   a.click();
  // }, 1000);
};

function download() {
  const title = localStorage.getItem("title");

  let html = localStorage.getItem("attrTxt");
  const plainText = covertHtmlToPlainText(html);
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(plainText)
  );
  element.setAttribute("download", title);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  toast.success(`Exporting script...`, toastOptions);

  document.body.removeChild(element);
}
