import React, { useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import $ from "jquery";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import {
  addCustomScriptSpaceController,
  removeAddRandomIdController,
} from "../utils/VoiceController";
var Url = require("url-parse");

const DummySpeech = () => {
  const [temp, setTemp] = useState(0);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const appendText = async (transcript) => {
    if (
      transcript !== undefined &&
      transcript.toLowerCase().trim().length > 0
      // transcript !== searchText
    ) {
      // setSearchText(transcript);

      if (window.find(transcript) === false) {
      }
      const testText = await transcript.trim().split(" ").splice(-3);
      const normalText = testText.toString();
      // console.log(normalText);
      const plainNormalText = normalText.split(",").join(" ");
      const plainText = plainNormalText.split("'s").join("");
      // console.log(plainNormalText);
      // speachToScrollController(plainNormalText, getOffsetToScroll);
      getOffsetToScroll(plainText);
    }
  };

  // speach to scroll page
  let testId;
  // replace the match words into the random Id's
  const getOffsetToScroll = (matchText) => {
    const inputText = matchText.toLowerCase();
    console.log(inputText, "asdf");
    const data = Date.now();
    var randomId = `imhere${data}`;

    $("#test12").html(
      $("#test12")
        .html()
        .replace(inputText, `<a  id=${randomId}></a>` + inputText)
    );
    scriptScrollToTop(randomId, inputText);
  };
  //when script is matched then script scroll
  const scriptScrollToTop = (randomId, matchText) => {
    var offset = document.getElementById(randomId);
    var offsetTop = 0;
    if (offset === null) {
      // return false;
    } else {
      offsetTop = offset.offsetTop; //getting offsetTop of the current random  id
      if (temp > offsetTop) {
        // console.log("less", temp);
        removeAddRandomIdController(randomId);
        $("html,body").animate({ scrollTop: temp });
        // return false;
      } else {
        offsetTop = offset.offsetTop;

        var offset_top = offsetTop;

        setTemp(offset_top);
        $("html,body").animate({ scrollTop: offset_top });
        testId = randomId;
        // whenever script should be scroll then add a space between the matched text
        addCustomScriptSpaceController(randomId, matchText);
      }
    }
  };

  useEffect(() => {
    appendText(transcript && transcript);
  }, [transcript && transcript]);
  var url = new Url(window.location.href);
  const substringData = url.hash.substring(5);
  const query = queryString.parse(substringData);

  let data = JSON.parse(query.response);

  return (
    <div>
      <h6>{data && data.title}</h6>
      <p>{data && data.attrText}</p>
      {/* <div className="voice-btns">
        <button
          onClick={() => SpeechRecognition.startListening({ continuous: true })}
        >
          Start
        </button>
        <button onClick={SpeechRecognition.stopListening}>Stop</button>
        <button
          onClick={() => {
            window.location.reload();
          }}
        >
          Reset
        </button>
      </div> */}
    </div>
  );
};
export default DummySpeech;
