import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import axios from "axios";
import LoadingOverlay from "react-loading-overlay";

import "./StripePayment.css";
import {
  addFreeOrdersInDb,
  create_payment_intent_without_userId,
  pay_now,
} from "../../utils/ApiList.js";
import { ToastContainer, toast } from "react-toastify";
import logo from "../SubscriptionComponent/images/logo.png";

import { refreshPageController } from "../../utils/RefreshPage.js";

import RedirectAppStripeCheckoutForm from "./RedirectAppStripeCheckoutForm.js";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DefaultStripeForm from "./DefaultStripeForm";
import { useHistory } from "react-router-dom";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

export default function RedirectAppStipePayment() {
  let stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
  const [clientSecret, setClientSecret] = useState("");
  const [loader, setLoader] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isAccount, setIsAccount] = useState(false);
  const [isVerify, setIsVerify] = useState(false);

  const [validateUser, setValidateUser] = useState(false);
  const history = useHistory();

  const handleResetForm = () => {
    setPassword("");
    setEmail("");
    setFirstName("");
    setLastName("");
    setIsVerify(false);
    setLoader(false);
  };

  let type = new URLSearchParams(window.location.search).get("type");

  useEffect(() => {
    if (type && type == 1) {
      setIsAccount(true);
      return;
    }
    if (!type) {
      setIsAccount(false);
      return;
    }
    setIsAccount(false);
  }, [type]);

  const createNewAccount = async (e) => {
    setLoader(true);

    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === ""
    ) {
      toast.warn("All fields are mandatory?");
      setLoader(false);

      return;
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/user_signup`,
          {
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            type: "Normal",
            unique_id: "",
            device_type: "WINDOWS",
          }
        );

        if (res.status === 200) {
          if (res.data.success === 0) {
            toast.error("Invalid email address, Please try again.");
            setLoader(false);
            return false;
          }
          const { message, data } = res.data;
          localStorage.removeItem("userId");
          localStorage.removeItem("user");

          if (data) {
            const OrderDetail = await axios.post(addFreeOrdersInDb, {
              user_id: data && data.id,
            });

            const userId = data && data.id;
            let subscriptionData = localStorage.getItem("subscriptionData");
            let sub = JSON.parse(subscriptionData);
            sub.user_id = userId;
            sub.plan_id = sub.price_id;
            let obj = {
              email,
              password,
              firstName,
              lastName,
            };
            localStorage.setItem("verifiedUserDetails", JSON.stringify(obj));

            localStorage.setItem("userId", userId);
            localStorage.setItem("user", JSON.stringify(data));
            await getValidPaymentIntent("new");
            localStorage.setItem(
              "subscriptionData",
              JSON.stringify(sub && sub)
            );
            setLoader(false);
          }
        }
        setLoader(false);
      } catch (error) {
        toast.error(error.message);
      }
      setLoader(false);
    }
  };

  const verifyUser = async () => {
    if (email === "") {
      toast.warn("Please enter valid email address?");
      setLoader(false);
      return;
    }
    setLoader(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify_user_email`,
        {
          email: email,
        }
      );
      if (data.code === 200) {
        const userId = data && data.data.id;
        let subscriptionData = localStorage.getItem("subscriptionData");
        let sub = JSON.parse(subscriptionData);
        sub.user_id = userId;
        sub.plan_id = sub.price_id;
        localStorage.setItem("userId", userId);
        let obj = {
          email,
          password,
          firstName,
          lastName,
        };
        localStorage.setItem("verifiedUserDetails", JSON.stringify(obj));
        localStorage.setItem("user", JSON.stringify(data));
        await getValidPaymentIntent();
        localStorage.setItem("subscriptionData", JSON.stringify(sub && sub));
      } else {
        toast.error(
          "User Email doesn't exist. Please try again or create a new account."
        );
        setLoader(false);
        return false;
      }
    } catch (error) {
      toast.error(error.message);
      setLoader(false);
      return false;
    }
  };

  const getValidPaymentIntent = async (data) => {
    if (data) {
      history.push("/app/subscription/checkout/payment?type=1");
    } else {
      history.push("/app/subscription/checkout/payment");
    }
  };

  return (
    <LoadingOverlay active={false} spinner>
      <div className="checkout-page new-redirect-checkout-page">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="logo-top mt-2">
              <img src={logo} className="img-fluid" />
              <h2 className="pt-2 pb-3">Get Scriptively Pro</h2>
            </div>
            <div className={`toggle-text `}>
              <span
                onClick={() => {
                  setIsAccount(false);
                  handleResetForm();
                }}
                className={`${!isAccount && "tab-color"}`}
              >
                New Account
              </span>
              <span>|</span>
              <span
                onClick={() => {
                  handleResetForm();
                  setIsAccount(true);
                }}
                className={isAccount && "tab-color"}
              >
                Existing Account
              </span>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <p className="text-signup text-left checkout-titles">
              {isAccount ? "Upgrade Existing Account" : "Create Your Account"}
            </p>
            <p></p>
          </div>
        </div>

        {!isAccount ? (
          <>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control custom-input"
                    name="firstName"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="lastName"
                    className="form-control custom-input"
                    name="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control custom-input"
                    name="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control custom-input"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <button
                    onClick={(e) => createNewAccount(e)}
                    className={`btn btn-primary btn-lg btn-block custom-btn border-0 btn-login create-account-checkout ${
                      isVerify && "verify-account"
                    }`}
                  >
                    {loader
                      ? "Please wait..."
                      : !isVerify
                      ? "Create Account"
                      : "Account Created"}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div
              className="col-lg-9 col-md-8 col-sm-8 col-6"
              style={{ padding: "0 15px" }}
            >
              <div className="form-group">
                <input
                  type="text"
                  className="form-control custom-input"
                  name="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div
              className="col-lg-3  col-md-4 col-sm-4 col-6"
              style={{ padding: "0 10px 0px 0px" }}
            >
              <div className="form-group">
                <button
                  onClick={(e) => verifyUser(e)}
                  className={`btn btn-primary btn-lg btn-block custom-btn border-0 btn-login ${
                    isVerify && "verify-account"
                  }`}
                >
                  {loader
                    ? "Please wait..."
                    : !isVerify
                    ? "Verify Account"
                    : "Account Verified"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* dummy form */}

        <DefaultStripeForm />

        <div className="row pb-5 mt-2">
          <div className="col-12 text-center">
            <div>
              <span>
                <VerifiedUserIcon className="shield_icon" />
              </span>
              <span>
                Secure Payments by{" "}
                <span className="text-shield-stripe">Stripe</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer className="alert-msg" autoClose={3000} />
    </LoadingOverlay>
  );
}
