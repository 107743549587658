import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateScriptConstants } from "../services/actions/constants";
import DraggableModal from "./DragableModal/DragableModal";

import "./UserTab.css";
import { Redirect, useLocation } from "react-router-dom";

function UserTab({ scrollTab, emailAndPrintAction, recentScrList }) {
  const search = useLocation();

  const modeType = search.pathname === "/development" ? true : false;

  const [open, setOpen] = React.useState(false);
  const { scriptData } = useSelector((state) => state.selectedScriptData);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    localStorage.removeItem("scrollTop");
    setOpen(false);
  };

  function createPopupWin(pageURL, pageTitle, popupWinWidth, popupWinHeight) {
    if (modeType) {
      if (!scriptData) {
        dispatch({
          type: updateScriptConstants.SELECTED_SCRIPT,
          payload: { script: recentScrList.length > 0 && recentScrList[0] },
        });
        localStorage.removeItem("scrollTop");
        setOpen(true);
      }
      localStorage.removeItem("scrollTop");
      setOpen(true);
      return;
    }
    var left = (window.screen.width - popupWinWidth) / 2;
    var top = (window.screen.height - popupWinHeight) / 4;

    var myWindow = window.open(
      pageURL,
      pageTitle,
      "resizable=yes, width=" +
        popupWinWidth +
        ", height=" +
        popupWinHeight +
        ", top=" +
        top +
        ", left=" +
        left +
        "frame=false,nodeIntegration=no"
    );
  }

  return (
    <div>
      <DraggableModal
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
      />
      <input
        className="input-custom"
        id="tab2"
        type="radio"
        onClick={() => scrollTab()}
      />

      <label className="">
        <div className="circle-bottom">
          {emailAndPrintAction ? (
            <a
              onClick={() =>
                createPopupWin("/prompt", "Scriptively Prompt", 1200, 650)
              }
            >
              <img src="assets/images/user-icon.svg" width="25px" alt="user" />{" "}
            </a>
          ) : (
            <img
              src="assets/images/user-icon.svg"
              width="25px"
              alt="user"
              style={{ cursor: "pointer" }}
              onClick={() =>
                toast.warn("Please select the script", {
                  hideProgressBar: true,
                })
              }
            />
          )}

          <span></span>
        </div>
      </label>
    </div>
  );
}

export default UserTab;
