import axios from "axios";
import { toast } from "react-toastify";
import { toastOptions } from "./ToastAlert";

// changeThumbnailPosition
export const changeThumbnailPosition = () => {
  const move = document.getElementById("movable");
  move.style.bottom = "10vh";
};
//disable mouse click in main page
export const disableEditController = () => {
  document.getElementById("scrptContainer").contentEditable = false;
  document.getElementById("contentDiv").style.pointerEvents = "none";
};

//getting div content Height

export const contentHeightController = () => {
  const contentSize = document.querySelector("#contentDiv").offsetHeight;
  // localStorage.setItem("contentHeight", contentSize);
  localStorage.setItem("contentHeight", "10000");
};

// hide Footer
export const scrollingFooterController = () => {
  const scrFooter = document.getElementById("scrolling__footer");
  scrFooter.style.display = "inline";
  const seekBarFooter = document.getElementById("seek__footer");
  seekBarFooter.style.display = "none";
};

// hide and show seek bar

export const seekBarFooterController = () => {
  const seekBarFooter = document.getElementById("seek__footer");
  seekBarFooter.style.display = "inline";
  const scrFooter = document.getElementById("scrolling__footer");
  scrFooter.style.display = "none";
};

// stop scroll controller
export const stopScrollController = (stopScrollData) => {
  const { stopAudioRecording, setPlayAction, timers } = stopScrollData;

  document.getElementById("scroll__header").style.display = "block";
  while (timers.length > 0) {
    clearTimeout(timers.pop());
  }

  if (stopAudioRecording === true) {
    setPlayAction(true);
  } else {
    setPlayAction(false);
  }
};

// start video controller

export const startVideoController = (startVideoControllerPassData) => {
  const { setRecordIcon, setFullMode, setThumbModes, setStopCamera } =
    startVideoControllerPassData;

  setRecordIcon(false);
  setFullMode(true);
  setThumbModes(true);
  // setPlayer(true);
  navigator.getUserMedia(
    {
      video: true,
      audio: true,
    },

    (stream) => {
      let video = document.getElementsByClassName("app__videoFeed")[0];
      let video1 = document.getElementsByClassName("app__videoFeed2")[0];
      let video2 = document.getElementsByClassName("app__videoFeed3")[0];
      setStopCamera(stream);
      if (video) {
        video.srcObject = stream;
      }
      if (video1) {
        video1.srcObject = stream;
      }
      if (video2) {
        video2.srcObject = stream;
      }
    },
    (err) => {
      toast.error("Media Device is not found", toastOptions);
    }
  );
};

// update prompt page setting
export const updatePromptScriptDataController = async (
  promptData,
  promptSettingData
) => {
  try {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/edit_script`, {
        user_id: promptData.user_id,
        script_id: promptData.script_id,
        scrTitle: promptData.scrTitle,
        scrText: promptData.scrText,
        scrAttrText: promptData.scrAttrText,
        scrEditTextSize: promptData.scrEditTextSize,
        scrPromptTextSize: promptSettingData.promptTextSize,
        scrPromptSpeed: 1,
        textMargin: promptSettingData.textMargin,
        marker: false,
        markerX: 140,
        uuid: promptData.uuid ? promptData.uuid : "",
        scrManualScrolling: promptSettingData.startVoiceRecognition
          ? "TRUE"
          : "FALSE",
        scrCameraRecordMe: promptSettingData.videoRecordMe ? "TRUE" : "FALSE",
        scrShowMeFullScreen: promptSettingData.scrFullScreen ? "TRUE" : "FALSE",
        scrShowMeThumbnail: promptSettingData.thumbnailCheckeded
          ? "TRUE"
          : "FALSE",
        scrVoiceRecordMe: promptSettingData.audioRecordChecked
          ? "TRUE"
          : "FALSE",
        scrColor: promptData.scrColor,
        scrAlignment: promptData.scrAlignment,
        scrMirror: promptSettingData.scrMirrorSetting,
        scrInvert: promptSettingData.scrInvertSetting,
        scrFontFamily: "arial",
      })

      .then(function (res) {
        const data = res.data.data;
        const msg = res.data.message;
        if (msg === "Script Successfully Updated") {
          let scriptLocalData = {
            user_id: data.user_id,
            script_id: data.id,
            scrTitle: data.scrTitle,
            scrText: data.scrText,
            scrAttrText: data.scrAttrText,
            uuid: data.uuid,
            scrAlignment: data.scrAlignment,
            scrInvert: data.scrInvert,
            scrMirror: data.scrMirror,
            textMargin: data.textMargin,
            scrEditTextSize: data.scrEditTextSize,
            scrPromptTextSize: data.scrPromptTextSize,
            scrShowMeThumbnail: data.scrShowMeThumbnail,
            scrShowMeFullScreen: data.scrShowMeFullScreen,
            scrCameraRecordMe: data.scrCameraRecordMe,
            scrVoiceRecordMe: data.scrVoiceRecordMe,
            scrManualScrolling: data.scrManualScrolling,
          };
          localStorage.setItem(
            "scriptLocalData",
            JSON.stringify(scriptLocalData)
          );

          promptSettingData.setPromptTextSize(data.scrPromptTextSize);

          promptSettingData.setLoader(false);
        } else {
        }
      });
  } catch (error) {
    console.log(error);
    promptSettingData.setLoader(false);
  }
};

// full video screen controller
export const fullVideoPlayController = (
  VideoPlayControllerData,
  startVideo,

  stopVideo
) => {
  const {
    scrFullScreen,
    recordChecked,
    setThumbnailChecked,
    videoStop,
    thumbModes,
    setRecordChecked,
  } = VideoPlayControllerData;

  if (recordChecked === true) {
    if (scrFullScreen === true) {
      // if (videoStop === true) {
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "inline";
      startVideo();

      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "none";
      const scriptPara = document.getElementById("contentDiv");
      scriptPara.style.opacity = "0.6";
      scriptPara.style.color = "#fff";
      setThumbnailChecked(false);
      console.log("stop video 201 scroll controller");

      // }
    } else {
      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "none";
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "none";
      document.getElementById("scroll__content").style.backgroundColor = "#000";
      document.getElementById("contentDiv").style.backgroundColor = "#000";
      document.getElementById("contentDiv").style.opacity = "1";
      setThumbnailChecked(false);
    }
  } else if (scrFullScreen === true) {
    const fullvideo = document.getElementById("full__video");
    fullvideo.style.display = "inline";
    startVideo();

    const thumb = document.getElementById("thumbnail__video");
    thumb.style.display = "none";
    const scriptPara = document.getElementById("contentDiv");
    scriptPara.style.opacity = "0.6";
    scriptPara.style.color = "#fff";
    setThumbnailChecked(false);
  } else {
    if (
      scrFullScreen === false &&
      videoStop === false &&
      thumbModes === false
    ) {
      setRecordChecked(false);
      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "none";
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "none";
      document.getElementById("scroll__content").style.backgroundColor = "#000";
      document.getElementById("contentDiv").style.backgroundColor = "#000";
      document.getElementById("contentDiv").style.opacity = "1";
      setThumbnailChecked(false);

      //stop green light
      stopVideo();
    } else if (
      scrFullScreen === true &&
      videoStop === false &&
      thumbModes === false
    ) {
      setRecordChecked(false);
      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "none";
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "none";
      document.getElementById("scroll__content").style.backgroundColor = "#000";
      document.getElementById("contentDiv").style.backgroundColor = "#000";
      document.getElementById("contentDiv").style.opacity = "1";
      setThumbnailChecked(false);

      //stop green light
      stopVideo();
    }
    const thumb = document.getElementById("thumbnail__video");
    thumb.style.display = "none";
    const fullvideo = document.getElementById("full__video");
    fullvideo.style.display = "none";
    document.getElementById("scroll__content").style.backgroundColor = "#000";
    document.getElementById("contentDiv").style.backgroundColor = "#000";
    document.getElementById("contentDiv").style.opacity = "1";
    setThumbnailChecked(false);

    //stop green light
    stopVideo();
  }
};

//move video thumb

export const hideVoiceErrorController = () => {
  document.getElementById("voiceRecoMedia").style.display = "none";
};

export const promptScrColorController = async (scrData) => {
  console.log(scrData);
  let { scrColor, setScrColor } = scrData;
  if (scrColor === "#300303") {
    setScrColor("#fff");
  }
  if (
    scrColor === "#0d0202" ||
    scrColor === "#999" ||
    scrColor === "#0b0909" ||
    scrColor === "#0d0c0c" ||
    scrColor === "#080808" ||
    scrColor === "#0d0d0d" ||
    scrColor === "#0a0a0a" ||
    scrColor === "#121212" ||
    scrColor === "#0f0f0f" ||
    scrColor === "#141414" ||
    scrColor === "#171717" ||
    scrColor === "#1a1a1a" ||
    scrColor === "#454545" ||
    scrColor === "#191919" ||
    scrColor === "#323232" ||
    scrColor === "#4c4c4c" ||
    scrColor === "#808080" ||
    scrColor === "#666666" ||
    scrColor === "#7f7f7f" ||
    scrColor === "#0c0c0c" ||
    scrColor === "#999999" ||
    scrColor === "#262626" ||
    scrColor === "#581845" ||
    scrColor === "#36454F" ||
    scrColor === "#023020" ||
    scrColor === "#301934" ||
    scrColor === "#343434" ||
    scrColor === "#1B1212" ||
    scrColor === "#28282B" ||
    scrColor === "#353935" ||
    scrColor === "#2b2b2b" ||
    scrColor === "#292929" ||
    scrColor === "#404040" ||
    scrColor === "#080707" ||
    scrColor === "#080707" ||
    scrColor === "#0b0a0a" ||
    scrColor === "#030202" ||
    scrColor === "#0e0b0b" ||
    scrColor === "#0c0909" ||
    scrColor === "#110d0d" ||
    scrColor === "#181111" ||
    scrColor === "#0e0707" ||
    scrColor === "#080202" ||
    scrColor === "#0c0303" ||
    scrColor === "#2b0d0d" ||
    scrColor === "#110404" ||
    scrColor === "#160404" ||
    scrColor === "#2e0505" ||
    scrColor === "#1f0505" ||
    scrColor === "#260303" ||
    scrColor === "#1c0101" ||
    scrColor === "#1c0303" ||
    scrColor === "#0a0404" ||
    scrColor === "#0a0101" ||
    scrColor === "#1a0606" ||
    scrColor === "#301010" ||
    scrColor === "#1a0505" ||
    scrColor === "#3b0202" ||
    scrColor === "#0a0000" ||
    scrColor === "#1c0202" ||
    scrColor === "#140000" ||
    scrColor === "#270c0c" ||
    scrColor === "#1d0c0c" ||
    scrColor === "#3a0e0e" ||
    scrColor === "#101010" ||
    scrColor === "#181818" ||
    scrColor === "#202020" ||
    scrColor === "#282828" ||
    scrColor === "#303030" ||
    scrColor === "#383838" ||
    scrColor === "#484848" ||
    scrColor === "#505050" ||
    scrColor === "#585858" ||
    scrColor === "#606060" ||
    scrColor === "#686868" ||
    scrColor === "#080000" ||
    scrColor === "#100000" ||
    scrColor === "#180000" ||
    scrColor === "#200000" ||
    scrColor === "#280000" ||
    scrColor === "#300000" ||
    scrColor === "#380000" ||
    scrColor === "#400000" ||
    scrColor === "#000033" ||
    scrColor === "#330000" ||
    scrColor === "#330033" ||
    scrColor === "#333300" ||
    scrColor === "#333333" ||
    // sdm

    scrColor === "#270202" ||
    scrColor === "#2e0a0a" ||
    scrColor === "#321616" ||
    scrColor === "#2c1c1c" ||
    scrColor === "#3c2020" ||
    scrColor === "#312525" ||
    scrColor === "#0f0606" ||
    scrColor === "#350d0d" ||
    scrColor === "#210d0d" ||
    scrColor === "#0e0101" ||
    scrColor === "#180606" ||
    scrColor === "#310202" ||
    scrColor === "#1d0707" ||
    scrColor === "#270707" ||
    scrColor === "#0f0505" ||
    scrColor === "#390404" ||
    scrColor === "#1f1414" ||
    scrColor === "#290f0f" ||
    scrColor === "#031026" ||
    scrColor === "#1f1f1f"
  ) {
    setScrColor("#fff");
  } else if (scrColor === "#000" || scrColor === "#000000") {
    setScrColor("#fff");
  } else if (scrColor === "#999") {
    setScrColor("#fff");
  } else if (scrColor === "") {
    setScrColor("#fff");
  }
};
