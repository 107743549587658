import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link, useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import MetaTags from "react-meta-tags";
import {
  login,
  isUserLoggedIn,
  signup,
  subscriptionStatus,
  loaderAction,
} from "../services/actions";
import { ToastContainer, toast } from "react-toastify";
import Recaptcha from "react-google-invisible-recaptcha";
import useFetch from "../hooks/useFetch";
import TiktokPixel from "tiktok-pixel";
import ReactPixel from "react-facebook-pixel";
const Signup = () => {
  const { loader } = useSelector((state) => state.requestLoader);
  const refRecaptcha = useRef();
  // const { data } = useFetch();
  const search = useLocation().search;

  const planType = new URLSearchParams(search).get("plan");

  let planArr = ["monthly", "6months", "yearly"];
  let index = planArr.indexOf(planType);

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const type = "Normal";

  // const [error, setError] = useState("error");
  let unique_id = "";

  const auth = useSelector((state) => state.auth);
  // console.log(auth)

  const user = useSelector((state) => state.user);

  // dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    const options = {
      debug: true, // enable logs
    };

    TiktokPixel.init(process.env.REACT_APP_TIK_TOK_PIXEL_ID, options);
  }, []);

  useEffect(() => {
    //  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID, options);

    ReactPixel.pageView(); // For tracking page view
    // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
    // ReactPixel.trackSingle("PixelId", event, data); // For tracking default events.
    // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
    // ReactPixel.trackSingleCustom("PixelId", event, data);
  }, []);

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
      dispatch(subscriptionStatus());
    }
  }, []);

  if (auth.authenticate) {
    return <Redirect to="/" />;
  }

  // sign up

  const userSignup = async (e) => {
    e.preventDefault();
    if (first_name === "") {
      toast.warn("All fields are mandatory?");
    } else if (last_name === "") {
      toast.warn("All fields are mandatory?");
    } else if (last_name === "") {
      toast.warn("All fields are mandatory?");
    } else if (email === "") {
      toast.warn("All fields are mandatory?");
    } else if (password === "") {
      toast.warn("All fields are mandatory?");
    } else {
      dispatch(loaderAction(true));

      if (!email || !password) {
        alert("Validation failed! Input cannot be empty.");
        refRecaptcha.current.reset();
        dispatch(loaderAction(false));
      } else {
        refRecaptcha.current.execute();
        const user_signup = {
          first_name,
          last_name,
          email,
          password,
          type,
          unique_id,
          device_type: "WINDOWS", // default device_type is WEB it mistake to WINDOWS, because Windows app is live.
        };

        if (index >= 0) {
          await dispatch(signup(user_signup, planType));
        } else {
          await dispatch(signup(user_signup));
        }
        await dispatch(subscriptionStatus());
        dispatch(loaderAction(false));
      }
    }
  };

  const onResolved = (value) => {
    // console.log(value);
  };

  // META PIXEL CONFIG

  return (
    <LoadingOverlay active={loader} spinner>
      <MetaTags>
        <title>Scriptively - Free Online Teleprompter</title>
        <meta
          name="description"
          content="Scriptively is a free online teleprompter, scriptwriter, & video recorder app designed to help you speak with more confidence and authority."
        />
        <meta name="keywords" content="Teleprompter online free" />
      </MetaTags>
      <div className="container-fluid login-container ">
        <div className="row">
          <div className="col-md-6 br-right">
            <div className="login-side">
              <h5>Script, Prompt, and Speak!</h5>
              <img
                src="assets/images/scriptively-speaker.png"
                alt="profile_img"
                // className="img-fluid"
              />
              <h6>Become a better speaker, teacher, and leader.</h6>
            </div>
          </div>

          {/* login form */}

          <div className="col-md-6 pl-0">
            <div className="login-section">
              <div className="login-inner">
                <div className="profile-img">
                  <img
                    src="assets/images/scriptively-logo.png"
                    alt="profile_img"
                    className="ml-4"
                  />
                </div>
                <div className="container login-form-content">
                  <h6>Create your Free Account</h6>
                  <p className="text-signup">
                    Already have a Scriptively account?{" "}
                    {index >= 0 ? (
                      <Link
                        to={"/login?plan=" + planType}
                        className="link_color pl-1"
                      >
                        Sign in
                      </Link>
                    ) : (
                      <Link to="/login" className="link_color pl-1">
                        Sign in
                      </Link>
                    )}
                  </p>
                  <form onSubmit={userSignup}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control custom-input"
                            name="firstname"
                            placeholder="First Name"
                            value={first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control custom-input"
                            name="lastname"
                            placeholder="Last Name"
                            value={last_name}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control custom-input"
                        name="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control custom-input"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <Recaptcha
                      ref={refRecaptcha}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_API}
                      onResolved={(value) => onResolved(value)}
                    />
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block custom-btn border-0"
                      >
                        Get Started
                      </button>
                    </div>
                  </form>
                  <div className="row">
                    {/* <div className="col-lg-6 col-md-6 col-sm-12">
                      <FacebookLogin />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <GoogleLogin />
                    </div> */}
                    <div className="col-12">
                      <p>No credit card required </p>
                      <p className="pt-4">
                        By clicking ‘Get Started’ you agree to Scriptively’s{" "}
                        <a
                          href="https://scriptively.com/tou/"
                          target="_blank"
                          className="link_color terms"
                        >
                          Terms of Service
                        </a>{" "}
                        and
                        <a
                          href="https://scriptively.com/privacy/"
                          target="_blank"
                          className="link_color terms pl-1"
                        >
                          Privacy Policy.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div id="menu1" className="container tab-pane fade">
                  <br /> */}
            {/* <p id="msg" className="text-danger">
                  {user.message}
                </p> */}

            <div className="row">
              {/* <div className="col-lg-6">
                      <FacebookLogin
                        cssClass="fb-btn"
                        appId={appId}
                        autoLoad={false}
                        fields="name, email, picture"
                        icon={<i className="fa fa-facebook" />}
                        textButton="&nbsp;&nbsp;Sign in with Facebook"
                      />
                    </div>
                    <div className="col-lg-6">
                      <GoogleLogin />
                    </div> */}
              {/* <div className="col-12">
                      <AppleAuth />
                    </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* </div>
        </div> */}
      <ToastContainer className="alert-msg" autoClose={5000} />
      {/* </div> */}
    </LoadingOverlay>
  );
};

export default Signup;
